import { useEffect, useState } from "react"
import XIcon from "@heroicons/react/solid/XIcon"
import { useTrans } from "@/i18n"
import { Input, TextArea } from "../form-controls"
import { TextAreaProps } from "../form-controls/TextArea"
import { classNames } from "@/lib/classnames"
import { InputProps } from "../form-controls/Input"
import { DialogWrapper } from "./DialogWrapper"

type TextareaType = {
	type: "textarea"
	props?: TextAreaProps
}

type InputType = {
	type: "input"
	props?: InputProps
}

export interface PromptDialogProps {
	isOpen: boolean
	title: string
	prompt: string
	onConfirm?: (...props: any[]) => any
	onClose?: () => void
	buttonText?: {
		ok?: string
		cancel?: string
	}
	dialogCallbackProps?: { [key: string]: any }
	inputProps?: InputType | TextareaType
}

export const PromptDialog = ({
	isOpen,
	title,
	prompt,
	onConfirm,
	onClose,
	buttonText,
	inputProps,
	...dialogCallbackProps
}: PromptDialogProps) => {
	const t = useTrans("dialog")
	const [value, setValue] = useState<string>("")

	useEffect(() => {
		if (!isOpen) {
			setValue("")
		}
	}, [isOpen])

	return (
		<>
			{isOpen && (
				<DialogWrapper>
					<div className="flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600">
						<h3 className="text-xl font-semibold text-gray-900 dark:text-white lg:text-2xl">
							{title}
						</h3>
						<button
							onClick={onClose}
							type="button"
							className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
							data-modal-toggle="default-prompt"
						>
							<XIcon
								width={24}
								className="text-2xl text-gray-500"
							/>
						</button>
					</div>

					<div className="space-y-6 p-6">
						<p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
							{prompt}
						</p>

						{inputProps?.type === "textarea" &&
							(() => {
								const { className, ...rest } =
									inputProps.props || {}

								return (
									<TextArea
										data-testid="textarea-prompt"
										onChange={({
											currentTarget: { value },
										}) => setValue(value)}
										value={value}
										className={classNames(
											"w-full resize-none",
											className,
										)}
										{...rest}
									/>
								)
							})()}
						{inputProps?.type === "input" &&
							(() => {
								const { className, ...rest } =
									inputProps.props || {}

								return (
									<Input
										data-testid="input-prompt"
										onChange={({
											currentTarget: { value },
										}) => setValue(value)}
										value={value}
										className={classNames(
											"w-full border-primary-500 hover:border-primary-600",
											className,
										)}
										{...rest}
									/>
								)
							})()}
					</div>

					<div
						className="flex items-center space-x-2 rounded-b border-t border-gray-200 p-6 dark:border-gray-600"
						data-testid="loading-screen-minimal"
					>
						<button
							onClick={onClose}
							data-modal-toggle="default-prompt"
							type="button"
							className="ml-auto rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900
									focus:z-10 focus:ring-4 focus:ring-gray-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
							data-testid="close-dialog-button"
						>
							{buttonText?.cancel
								? buttonText?.cancel
								: t("dialog.prompt.button_cancel")}
						</button>
						<button
							onClick={() => {
								if (typeof onConfirm === "function") {
									onConfirm(
										...[
											value,
											...Object.values(
												dialogCallbackProps,
											),
										],
									)
								} else {
									console.warn(
										"You did not provide a valid callback for <PromptDialog />",
									)
								}
							}}
							data-modal-toggle="default-prompt"
							type="button"
							className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
							data-testid="confirm-dialog-button"
						>
							{buttonText?.ok
								? buttonText?.ok
								: t("dialog.prompt.button_ok")}
						</button>
					</div>
				</DialogWrapper>
			)}
		</>
	)
}

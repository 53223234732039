import { ReactNode, useEffect, useState } from "react"

// Router
import { Link } from "react-router-dom"
import { Pages } from "@/misc/pages"

import { AnimatePresence, motion } from "@/lib/animations"
import { formatToDecimalPlaces } from "@/lib/math"
import { emptyValue, truncateFloatNumber } from "@/misc/helpers"

// Hooks
import { useCurrentProject } from "../hooks"
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"

// Icons
import { ScaleIcon } from "@heroicons/react/outline"
import { BsFillLightningFill } from "react-icons/bs"
import {
	FiArrowDown,
	FiArrowRight,
	FiArrowUp,
	FiCoffee,
	FiSun,
	FiTrendingUp,
} from "@/lib/icons"
import { IoBulbOutline, IoCarOutline } from "react-icons/io5"

// UI
import { classNames } from "@/lib/classnames"
import { Card, CardBody, CardWrapper } from "@/components/Card"
import { graphConfig } from "@/components/Graphs"
import { MiniGraph, MiniGraphStatic } from "@/components/MiniGraph"
import { PillInverted } from "@/components/Pill"
import { StatusMessage } from "@/components/StatusMessage"
import { Heading } from "@/components/Typography"
import { Tooltip } from "@/components/tooltips/Tooltip"

// GraphQL
import {
	ProjectTypeEnum,
	useInterestPaymentsQuery,
	useProjectRevenueQuery,
	PayOutState,
} from "@/api/graphql"

// Translations
import { Trans, useTrans } from "@/i18n"
import { useLang } from "@/context/lang"
import { dateToLocaleString, localeStringOptionsWithYear } from "@/lib/dates"

// Dates
import { useGetDateDiff } from "@/hooks/useShortDate"
import { DateTime, ms } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Graphs
import { LineChart } from "@/components/graphs/types/line/LineChart"

// Feature flags
import { useFeatureFlags } from "@/context/user"

/**
 * ProjectDashboard
 * @returns
 */
export const ProjectDashboard = () => {
	const t = useTrans(["common", "project"])
	const id = useCurrentProjectId()
	const { data } = useCurrentProject()
	const { formatCurrency } = useLang()
	const { getFeatureFlagValue } = useFeatureFlags()

	// Dates
	const getDateDiff = useGetDateDiff()
	const now = DateTime.local()
	const then = DateTime.local().minus({ days: 4 })

	const { data: projectRevenueData } = useProjectRevenueQuery({
		id: String(id),
	})

	const { data: { me = {} } = { me: {} } } = useInterestPaymentsQuery({
		id: String(id),
		project_id: String(id),
		ordering: "-interestperiod__end",
	})

	// Select the last payment based on date (first in array) where payment entries are done
	const lastPayment = me?.interest_payments
		?.filter((payment) =>
			payment.payment_entries?.some(
				(entry) =>
					entry?.state === null || // Payouts before the Mangopay migration (2024) have state 'null'. We always include them.
					entry?.state === PayOutState.PayoutCompleted ||
					entry?.state === PayOutState.TransferCompleted,
			),
		)
		.at(0)
	const interestPeriodEndDate = lastPayment?.interestperiod?.end

	const lastPaymentEndDate = interestPeriodEndDate
		? DateTime.fromISO(interestPeriodEndDate)
		: null

	const { cost, amortization, repayment } =
		lastPayment?.payment_entries?.at(0) || {}

	const lastPaymentSum =
		Number(cost) + Number(amortization) + Number(repayment)

	// Sum total of all payments that have status DONE
	const paymentSum = me?.interest_payments
		?.filter((payment) =>
			payment.payment_entries?.some(
				(entry) =>
					entry?.state === null || // Payouts before the Mangopay migration (2024) have state 'null'. We always include them.
					entry.state === PayOutState.TransferCompleted ||
					entry.state === PayOutState.PayoutCompleted,
			),
		)
		?.reduce((acc, curr) => {
			const payment = curr.payment_entries?.at(0)

			return (
				acc +
				(Number(payment?.cost) +
					Number(payment?.amortization) +
					Number(payment?.repayment))
			)
		}, 0)

	const interestRate =
		projectRevenueData?.project?.investor_shares_value_stats
			?.internal_rate_of_returns?.internal_rate_of_return
	const interestRateFixed = truncateFloatNumber(Number(interestRate))

	const expectedInterestRate =
		projectRevenueData?.project?.investor_shares_value_stats
			?.internal_rate_of_returns?.expected_internal_rate_of_return
	const expectedInterestRateFixed = truncateFloatNumber(
		Number(expectedInterestRate),
	)

	// Calculate initial investment
	const initalInvestment = Number(
		(data?.project?.investor_shares_value_stats?.shares?.[0]
			?.nominal_value || 0) *
			(data?.project?.investor_shares_value_stats?.total_shares || 0),
	)

	return (
		<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
			<Card className="md:col-span-4 xl:col-span-3">
				<div className="mb-4 md:mb-2 md:flex md:items-center md:justify-start">
					<Heading as="h2" styleAs="h5" className="mb-2 md:mb-0">
						{t("project:project.dashboard.hero.title", {
							projectName: data?.project?.name,
						})}
					</Heading>

					{/** Project Type */}
					{data?.project?.type && (
						<div className="inline-block rounded-md bg-primary-500 px-4 py-1 font-bold text-black opacity-90 shadow-2xl md:ml-4 md:mt-2 lg:mt-0">
							{t(
								`common:common.project.type.${data?.project?.type}`,
							)}
						</div>
					)}

					{/** Project State */}
					{data?.project?.state && (
						<PillInverted
							variant={data.project.state}
							className="ml-4 inline-block md:mt-2 lg:mt-0"
						>
							{t(
								`common:common.project.status.${data.project.state}`,
							)}
						</PillInverted>
					)}
				</div>
				{!!me?.investment_campaigns?.at(0)?.description?.length && (
					<p className="hidden overflow-ellipsis text-sm text-gray-700 md:block xl:w-1/2">
						{me.investment_campaigns.at(0)?.description}
					</p>
				)}
				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:mt-6 xl:grid-cols-4">
					{/** Share value */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500">
							{projectRevenueData?.project?.type ===
							ProjectTypeEnum.Portfolio
								? t(
										"project:project.dashboard.hero.bond_value.title",
								  )
								: t(
										"project:project.dashboard.hero.share_value.title",
								  )}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(
								Number(
									data?.project?.investor_shares_value_stats
										?.total_investment_for_project,
								),
							)}
						</dd>
					</div>

					{/** Total shares */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-solarshares"
					>
						<dt className="text-sm font-medium text-gray-500">
							{projectRevenueData?.project?.type ===
							ProjectTypeEnum.Portfolio
								? t(
										"project:project.dashboard.hero.solar_bonds.title",
								  )
								: t(
										"project:project.dashboard.hero.solar_shares.title",
								  )}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{
								data?.project?.investor_shares_value_stats
									?.total_shares
							}
						</dd>
					</div>

					{/** Interest Rate */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-interestrate"
					>
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"project:project.dashboard.hero.interest_rate.title",
							)}
							<Tooltip
								text={t(
									`project:project.dashboard.hero.interest_rate.description${
										data?.project?.type ===
										("PORTFOLIO" as ProjectTypeEnum)
											? "_pro_project"
											: ""
									}`,
									{
										interestRate: interestRateFixed,
									},
								)}
							/>
						</dt>
						<dd className="mt-1 flex text-sm text-gray-900">
							{interestRateFixed}% ({expectedInterestRateFixed}%){" "}
							<FiTrendingUp className="ml-2 h-5 w-5 flex-shrink-0 transform self-center text-green-500" />
						</dd>
					</div>

					{/** Initial investment */}
					<div
						className="col-span-1 rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500">
							{t(
								"project:project.dashboard.hero.initial_investment.title",
							)}
						</dt>
						<dd className="mt-1 text-sm text-gray-900">
							{formatCurrency(Number(initalInvestment))}
						</dd>
					</div>
				</div>
			</Card>

			{/** Project photo */}
			<Card
				className={`${classNames(
					!data?.project?.image_url && "invisible",
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			>
				{data?.project?.image_url ? (
					<img
						src={data?.project?.image_url}
						alt={data?.project?.name || ""}
					/>
				) : (
					data?.project?.supplier?.name
				)}
			</Card>

			{/** Energy supplier */}
			<Card
				className={`${classNames(
					!data?.project?.supplier && "invisible",
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			>
				{data?.project?.supplier?.logo ? (
					<img
						src={data?.project?.supplier?.logo}
						alt={data?.project?.supplier?.name}
					/>
				) : (
					data?.project?.supplier?.name
				)}
			</Card>

			<div className="grid grid-cols-1 gap-8 md:col-span-4 lg:grid-cols-2 xl:col-span-3">
				<ItemWithLink
					to={`${Pages.InvestmentsProjects}/${id}/revenue`}
					className="md:col-span-1 xl:col-span-1"
					title={t("project:project.dashboard.payments.title")}
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.payments.last_payment.title",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{lastPaymentSum
									? formatCurrency(lastPaymentSum)
									: emptyValue()}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.payments.last_payout.title",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{lastPaymentEndDate
									? lastPaymentEndDate.toFormat(dateFormat)
									: emptyValue()}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.payments.total_payments.title",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{formatCurrency(paymentSum || 0)}
							</dd>
						</div>
					}
				/>
				<Item
					title={t("project:project.dashboard.details.title")}
					className="md:col-span-1 xl:col-span-1"
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.start")}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{DateTime.fromISO(
									data?.project?.start,
								).toFormat(dateFormat)}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.end")}
							</dt>
							<dd className="text-right text-sm text-gray-900">
								{DateTime.fromISO(data?.project?.end).toFormat(
									dateFormat,
								)}
							</dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.details.owner.email",
								)}
							</dt>
							<dd className="text-right text-sm font-medium text-gray-900">
								{data?.project?.owner?.email}
							</dd>
						</div>
					}
				/>
			</div>
			{getFeatureFlagValue("ENABLE_EXTENDED_PROJECT_DASHBOARD") ===
				true && (
				<>
					<ItemWithLink
						to={Pages.Communications}
						title={t(
							"project:project.dashboard.communications.title",
						)}
						className="md:col-span-4 xl:col-span-2"
						content={
							<div className="space-y-3">
								<p className="text-sm text-gray-900">
									<StatusMessage
										content={`Changed status to <pill variant="IN_FUNDING">producing</pill">`}
									/>
								</p>
								<p className="text-xs text-gray-500">
									<Trans
										ns="project"
										i18nKey="project:project.dashboard.communications.last_updated_message"
										values={{
											// TODO: This translation is broken
											date: t(
												`common:common.${getDateDiff(
													now,
													then,
												)}`,
											),
										}}
										components={{
											time: (
												<time
													title={then.toLocaleString()}
												/>
											),
										}}
									></Trans>
								</p>
							</div>
						}
					/>
					<CardWrapper className="col-span-1 md:col-span-2 lg:col-span-4 xl:col-span-2">
						<EnergyProducedGraph />
					</CardWrapper>
					<CardWrapper className="col-span-1 md:col-span-2 lg:col-span-4 xl:col-span-2">
						<YourReturnsGraph />
					</CardWrapper>
					<CardWrapper className="col-span-1 lg:col-span-4 2xl:col-span-4">
						<YourSavings />
					</CardWrapper>
					<CardWrapper className="md:col-span-2 lg:col-span-2 2xl:col-span-1">
						<ProductionTodayMiniGraphSmall />
					</CardWrapper>
					<CardWrapper className="md:col-span-2 lg:col-span-2 2xl:col-span-1">
						<PerformanceRatioGraph />
					</CardWrapper>
				</>
			)}
		</div>
	)
}

/**
 * ProjectDashboardLoadingState
 * @returns
 */
export const ProjectDashboardLoadingState = () => {
	const t = useTrans(["common", "project"])
	return (
		<div className="grid grid-cols-1 gap-8 md:grid-cols-4">
			<Card className="md:col-span-4 xl:col-span-3">
				<div className="mb-4 md:mb-2 md:flex md:items-center md:justify-start">
					<Heading
						as="h2"
						styleAs="h5"
						className="mb-2 md:mb-0"
					></Heading>
				</div>
				<p className="hidden overflow-ellipsis text-sm text-gray-700 md:block xl:w-1/2"></p>
				<div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:mt-6 xl:grid-cols-4">
					{/** Share value */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 text-sm text-gray-900"></dd>
					</div>

					{/** Total shares */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-solarshares"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 text-sm text-gray-900"></dd>
					</div>

					{/** Interest Rate */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-interestrate"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 flex text-sm text-gray-900">
							<FiTrendingUp className="ml-2 h-5 w-5 flex-shrink-0 transform self-center text-green-500" />
						</dd>
					</div>

					{/** Initial investment */}
					<div
						className="col-span-1 animate-pulse rounded-md bg-gray-50 px-4 py-3"
						data-testid="project-sharevalue"
					>
						<dt className="text-sm font-medium text-gray-500"></dt>
						<dd className="mt-1 text-sm text-gray-900"></dd>
					</div>
				</div>
			</Card>
			{/** Project photo */}
			<Card
				className={`${classNames(
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			></Card>

			{/** Energy supplier logo */}
			<Card
				className={`${classNames(
					"items-center",
					"justify-center",
					"xl:flex",
					"md:col-span-2",
					"xl:col-auto",
				)}`}
			></Card>
			<div className="grid grid-cols-1 gap-8 md:col-span-4 lg:grid-cols-2 xl:col-span-3">
				<ItemWithLink
					to={""}
					className="md:col-span-1 xl:col-span-1"
					title={t("project:project.dashboard.payments.title")}
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500"></dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500"></dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500"></dt>
							<dd className="text-right text-sm text-gray-900"></dd>
						</div>
					}
				/>
				<Item
					title={t("project:project.dashboard.details.title")}
					className="md:col-span-1 xl:col-span-1"
					content={
						<div className="grid grid-cols-2 gap-2">
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.start")}
							</dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500">
								{t("common:common.date.end")}
							</dt>
							<dd className="text-right text-sm text-gray-900"></dd>
							<dt className="text-sm font-medium text-gray-500">
								{t(
									"project:project.dashboard.details.owner.email",
								)}
							</dt>
							<dd className="text-right text-sm text-gray-900"></dd>
						</div>
					}
				/>
			</div>
		</div>
	)
}

const YourSavings = () => {
	const t = useTrans("project")
	const savings = 10

	const values = {
		coffee: parseInt(t("project.your_savings.coffee.kwh")),
		driving: parseInt(t("project.your_savings.driving.kwh")),
		light: parseInt(t("project.your_savings.light.kwh")),
	}

	return (
		<CardWrapper>
			<CardBody>
				<Heading as="h5" className="text-gray-600">
					{t("project.your_savings.title")}
				</Heading>
				<div className="mt-4 grid grid-cols-1 gap-4 text-gray-500 md:mt-6 xl:grid-cols-3">
					<div className="col-span-1 flex flex-col items-center rounded-md bg-gray-50 px-4 py-6 lg:py-8">
						<dt>
							<FiCoffee size={40} />
						</dt>
						<dd className="mt-2 text-sm">
							<Trans
								ns="project"
								i18nKey="project.your_savings.coffee.copy"
								count={savings * values.coffee}
								components={{
									strong: (
										<span className="font-bold text-gray-900" />
									),
								}}
							/>
						</dd>
					</div>
					<div className="col-span-1 flex flex-col items-center rounded-md bg-gray-50 px-4 py-6 lg:py-8">
						<dt>
							<IoCarOutline size={40} />
						</dt>
						<dd className="mt-2 text-sm">
							<Trans
								ns="project"
								i18nKey="project.your_savings.driving.copy"
								count={savings * values.driving}
								components={{
									strong: (
										<span className="font-bold text-gray-900" />
									),
								}}
							/>
						</dd>
					</div>
					<div className="col-span-1 flex flex-col items-center rounded-md bg-gray-50 px-4 py-6 lg:py-8">
						<dt>
							<IoBulbOutline size={40} />
						</dt>
						<dd className="mt-2 text-sm">
							<Trans
								ns="project"
								i18nKey="project.your_savings.light.copy"
								count={savings * values.light}
								components={{
									strong: (
										<span className="font-bold text-gray-900" />
									),
								}}
							/>
						</dd>
					</div>
				</div>
			</CardBody>
		</CardWrapper>
	)
}

const EnergyProducedGraph = () => {
	const { formatNumber } = useLang()
	const t = useTrans(["common", "project"])
	const [value, setHeading] = useState<number | undefined>(135567445)
	const [label, setLabel] = useState<string | undefined>()

	let i18n = ""
	let displayValue = value ?? 135567445

	if (displayValue >= 1_000_000 && displayValue < 1_000_000_000) {
		i18n = "common:common.numbers.million"
		displayValue = formatToDecimalPlaces(displayValue / 1_000_000)
	} else if (
		displayValue >= 1_000_000_000 &&
		displayValue < 1_000_000_000_000
	) {
		i18n = "common:common.numbers.billion"
		displayValue = formatToDecimalPlaces(displayValue / 1_000_000_000)
	} else if (displayValue >= 1_000_000_000_000) {
		i18n = "common:common.numbers.trillion"
		displayValue = formatToDecimalPlaces(displayValue / 1_000_000_000_000)
	}

	return (
		<>
			<CardBody className="pr-0">
				<div className="grid grid-cols-stat-widget items-center gap-4">
					<FiSun
						className={`w-6 text-gray-400 transition group-hover:text-primary-500`}
						size={24}
					/>
					<div>
						<p className="text-sm text-gray-500">
							{t(
								"project:project.dashboard.energy_produced.title",
							)}
						</p>
						<Heading className="text-dark" as="h4">
							<Trans
								ns="project"
								i18nKey="project.dashboard.energy_produced.copy"
								values={{
									amount: i18n
										? t(i18n, {
												number: formatNumber(
													displayValue,
												),
										  })
										: formatNumber(displayValue),
								}}
								components={{ small: <Small /> }}
							/>
							<AnimatePresence>
								{label ? (
									<motion.span
										className="ml-2 font-sans text-sm font-normal normal-case text-gray-500"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
									>
										{dateToLocaleString(
											label,
											localeStringOptionsWithYear,
										)}
									</motion.span>
								) : null}
							</AnimatePresence>
						</Heading>
					</div>
				</div>
			</CardBody>
			<LineChart
				id="energy-produced"
				height={220}
				minHeight={220}
				setValue={setHeading}
				setLabel={setLabel}
				label={label}
				value={135567445}
				data={[
					{
						time: "2021-05-04",
						value: 135567445.24526823,
					},
					{
						time: "2021-05-05",
						value: 135567445.24526823,
					},
					{
						time: "2021-05-06",
						value: 351119659.36020577,
					},
					{
						time: "2021-05-07",
						value: 479825371.73502356,
					},
					{
						time: "2021-05-08",
						value: 541983951.7552034,
					},
					{
						time: "2021-05-09",
						value: 589741315.9498796,
					},
					{
						time: "2021-05-10",
						value: 564982950.6675421,
					},
					{
						time: "2021-05-11",
						value: 727801145.8999286,
					},
					{
						time: "2021-05-12",
						value: 811377392.3795153,
					},
					{
						time: "2021-05-13",
						value: 914572640.7354678,
					},
					{
						time: "2021-05-14",
						value: 974448145.6678767,
					},
					{
						time: "2021-05-15",
						value: 965951736.8199869,
					},
					{
						time: "2021-05-16",
						value: 986588193.3956493,
					},
					{
						time: "2021-05-17",
						value: 1015976866.7183036,
					},
					{
						time: "2021-05-18",
						value: 1106128492.8306503,
					},
					{
						time: "2021-05-19",
						value: 884973319.1466471,
					},
					{
						time: "2021-05-20",
						value: 1076325712.353824,
					},
					{
						time: "2021-05-21",
						value: 982871445.4489927,
					},
					{
						time: "2021-05-22",
						value: 992857983.6585948,
					},
					{
						time: "2021-05-23",
						value: 944014744.9121099,
					},
					{
						time: "2021-05-24",
						value: 1271360759.7839267,
					},
					{
						time: "2021-05-25",
						value: 1457518070.3227108,
					},
					{
						time: "2021-05-26",
						value: 1597557579.101118,
					},
					{
						time: "2021-05-27",
						value: 1576749120.1433103,
					},
					{
						time: "2021-05-28",
						value: 1556806472.2127428,
					},
					{
						time: "2021-05-29",
						value: 1536150999.5695941,
					},
					{
						time: "2021-05-30",
						value: 1630213530.4494455,
					},
					{
						time: "2021-05-31",
						value: 1798126502.1094875,
					},
					{
						time: "2021-06-01",
						value: 1751116331.0744963,
					},
				].map((data, index) => ({
					...data,
					value: Math.exp((index + 1) / 30) * 100,
				}))}
			/>
		</>
	)
}

const Small = ({ children }: { children?: ReactNode }) => (
	<span className="truncate font-sans text-base font-medium normal-case">
		{children}
	</span>
)

const YourReturnsGraph = () => {
	const { formatCurrency } = useLang()
	const t = useTrans(["common", "project"])
	const [value, setHeading] = useState<number | undefined>()
	const [label, setLabel] = useState<string | undefined>()

	let i18n = ""
	let displayValue = value ?? 75

	if (displayValue >= 1_000_000 && displayValue < 1_000_000_000) {
		i18n = "common:common.numbers.million"
		displayValue = formatToDecimalPlaces(displayValue / 1_000_000)
	} else if (
		displayValue >= 1_000_000_000 &&
		displayValue < 1_000_000_000_000
	) {
		i18n = "common:common.numbers.billion"
		displayValue = formatToDecimalPlaces(displayValue / 1_000_000_000)
	} else if (displayValue >= 1_000_000_000_000) {
		i18n = "common:common.numbers.trillion"
		displayValue = formatToDecimalPlaces(displayValue / 1_000_000_000_000)
	}

	return (
		<>
			<CardBody className="pr-0">
				<div className="grid grid-cols-stat-widget items-center gap-4">
					<ScaleIcon
						className={`w-6 text-gray-400 transition group-hover:text-primary-500`}
					/>
					<div>
						<p className="text-sm text-gray-500">
							{t(
								"project:project.dashboard.returns_to_date.title",
							)}
						</p>
						<Heading className="text-dark" as="h4">
							<Trans
								ns="project"
								i18nKey="project.dashboard.returns_to_date.copy"
								values={{
									amount: i18n
										? t(i18n, {
												number: formatCurrency(
													displayValue,
												),
										  })
										: formatCurrency(displayValue),
								}}
								components={{ small: <Small /> }}
							/>
							<AnimatePresence>
								{label ? (
									<motion.span
										className="ml-2 font-sans text-sm font-normal normal-case text-gray-500"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
									>
										{dateToLocaleString(
											label,
											localeStringOptionsWithYear,
										)}
									</motion.span>
								) : null}
							</AnimatePresence>
						</Heading>
					</div>
				</div>
			</CardBody>
			<LineChart
				id="your-returns-produced"
				height={220}
				minHeight={220}
				setValue={setHeading}
				setLabel={setLabel}
				label={label}
				value={76.4}
				min={74}
				max="dataMax"
				data={[
					{
						time: "2019-01-01",
						value: 75,
					},
					{
						time: "2020-01-01",
						value: 75.7,
					},
					{
						time: "2021-01-01",
						value: 76.4,
					},
				]}
			/>
		</>
	)
}

const ProductionTodayMiniGraphSmall = () => {
	const t = useTrans(["common", "project"])
	const { formatNumber } = useLang()
	const [data, set] = useState<Array<[number, number]>>([
		[+new Date() - 4000, 75],
		[+new Date() - 3000, 76],
		[+new Date() - 2000, 79],
		[+new Date() - 1000, 80],
		[+new Date(), 75],
	])

	useEffect(() => {
		const interval = setInterval(() => {
			set((data) => {
				const newData = data.slice(1).slice(-5)
				const [time] = newData[newData.length - 1]
				return [
					...newData,
					[time + 2000, Math.floor(Math.random() * 10) + 75],
				]
			})
		}, 2000)
		return () => {
			clearInterval(interval)
		}
	})

	return (
		<MiniGraphStatic
			icon={({ className }) => (
				<BsFillLightningFill className={className} size={24} />
			)}
			title={t("project:project.dashboard.production_today.title")}
			heading={() => {
				let value = data[data.length - 1][1]
				let i18n = ""
				if (value >= 1_000_000 && value < 1_000_000_000) {
					i18n = "common:common.numbers.million"
					value = formatToDecimalPlaces(value / 1_000_000)
				} else if (
					value >= 1_000_000_000 &&
					value < 1_000_000_000_000
				) {
					i18n = "common:common.numbers.billion"
					value = formatToDecimalPlaces(value / 1_000_000_000)
				} else if (value >= 1_000_000_000_000) {
					i18n = "common:common.numbers.trillion"
					value = formatToDecimalPlaces(value / 1_000_000_000_000)
				}

				return (
					<>
						<Trans
							ns="project"
							i18nKey="project.dashboard.production_today.copy"
							values={{
								amount: i18n
									? t(i18n, {
											number: formatNumber(value),
									  })
									: formatNumber(value),
							}}
							components={{ small: <Small /> }}
						/>
						{value > 80 ? (
							<FiArrowUp
								size={24}
								className="-mr-1 ml-1 inline-block self-center text-green-500"
							/>
						) : value < 80 ? (
							<FiArrowDown
								size={24}
								className="-mr-1 ml-1 inline-block self-center text-red-500"
							/>
						) : null}
					</>
				)
			}}
			data={data}
			series={[
				{
					...graphConfig.detail,
					yAxis: "dash",
					animation: false,
					type: "spline",
					dashStyle: "Dash",
					data: data.map(([time]) => {
						return [time, 80]
					}),
				},
			]}
			yAxis={{ max: 100 }}
			yAxisDash={{
				min: 0,
				max: 100,
			}}
		/>
	)
}

const PerformanceRatioGraph = () => {
	const interval = ms("1 day")
	const now = Number(new Date())
	const t = useTrans(["common", "project"])
	const { formatNumber } = useLang()

	return (
		<MiniGraph
			icon={({ className }) => <FiSun className={className} size={24} />}
			title={t("project:project.dashboard.performance_ratio.title")}
			heading={(value) => {
				let i18n = ""
				if (value >= 1_000_000 && value < 1_000_000_000) {
					i18n = "common:common.numbers.million"
					value = formatToDecimalPlaces(value / 1_000_000)
				} else if (
					value >= 1_000_000_000 &&
					value < 1_000_000_000_000
				) {
					i18n = "common:common.numbers.billion"
					value = formatToDecimalPlaces(value / 1_000_000_000)
				} else if (value >= 1_000_000_000_000) {
					i18n = "common:common.numbers.trillion"
					value = formatToDecimalPlaces(value / 1_000_000_000_000)
				}

				return (
					<>
						<Trans
							ns="project"
							i18nKey="project.dashboard.performance_ratio.copy"
							values={{
								amount: i18n
									? t(i18n, {
											number: formatNumber(value),
									  })
									: formatNumber(value),
							}}
							components={{ small: <Small /> }}
						/>
						{value > 80 ? (
							<FiArrowUp
								size={24}
								className="-mr-1 ml-1 inline-block self-center text-green-500"
							/>
						) : (
							<FiArrowDown
								size={24}
								className="-mr-1 ml-1 inline-block self-center text-red-500"
							/>
						)}
					</>
				)
			}}
			data={[
				[now + interval * 1, 94],
				[now + interval * 2, 93],
				[now + interval * 3, 92],
				[now + interval * 4, 92],
				[now + interval * 5, 91],
				[now + interval * 6, 90],
				[now + interval * 7, 91],
				[now + interval * 8, 91],
			].map(([time, value]) => ({
				date: `${time}`,
				value,
			}))}
		/>
	)
}

interface ItemProps {
	title: string
	content: ReactNode
	className?: string
	withLink?: boolean
}

const Item = ({ title, content, className = "", withLink }: ItemProps) => {
	return (
		<Card className={`${className} h-full group-hover:bg-gray-50`}>
			{withLink && (
				<FiArrowRight className="absolute right-5 top-5 transform text-gray-400 transition group-hover:translate-x-1/3" />
			)}
			<div className="space-y-3">
				<Heading as="h5" className="text-gray-600">
					{title}
				</Heading>
				{content}
			</div>
		</Card>
	)
}

interface ItemWithLinkProps {
	to: string
	title: string
	content: ReactNode
	className?: string
}

const ItemWithLink = ({
	to,
	className = "",
	title,
	content,
}: ItemWithLinkProps) => {
	return (
		<Link to={to} className={`${className} group relative`}>
			<Item title={title} content={content} withLink />
		</Link>
	)
}

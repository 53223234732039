// Generic
import { NotFound } from "@/pages/NotFound"
import { Info } from "@/pages/profile/pages/Info"

// Auth
import { Register } from "@/pages/account/Register"
import { Confirm } from "@/pages/account/Confirm"
import { SignIn } from "@/pages/account/SignIn"
import { Reset } from "@/pages/account/Reset"
import { Recover } from "@/pages/account/Recover"

// Misc
import { Profile } from "@/pages/profile"
import { Investor } from "@/pages/investor"
import { Recent } from "@/pages/profile/pages/Recent"
import { FinanceDashboard } from "@/pages/finance/pages/Dashboard"
import { FinancePerformance } from "@/pages/finance/pages/Performance"
import { FinanceSolarInterestTariff } from "@/pages/finance/pages/SolarInterestTariff"
import { Payments } from "@/pages/payments"
import { Project } from "@/pages/project"
import { ProjectNotFound } from "@/pages/project/ProjectNotFound"
import { InvestmentsPage } from "@/pages/investments"
import { AppSettings } from "@/pages/profile/pages/AppSettings"

// Stats
import { Dashboard } from "@/pages/statistics/AllProjectStats"
import { SingleProjectStats } from "@/pages/statistics/SingleProjectStats"
import { StatisticsMySavings } from "@/pages/statistics/StatisticsMySavings"

// Constants
import { Pages } from "./routes"
export { Pages } // Re-export for backwards compatibility

/**
 * Lazy loading components
 * DOCS: https://react.dev/reference/react/lazy
 */
export const LazyLoadingPages = {
	NotFound,
	Info,
	Register,
	Confirm,
	SignIn,
	Reset,
	Recover,
	Dashboard,
	Investor,
	Recent,
	FinanceDashboard,
	FinancePerformance,
	FinanceSolarInterestTariff,
	Payments,
	Project,
	ProjectNotFound,
	Investments: InvestmentsPage,

	// Stats
	StatisticsMySavings,
	SingleProjectStats,

	// Settings
	AppSettings,
	Profile,
}

import { HTMLAttributes, HTMLProps, ReactNode } from "react"
import { classNames } from "@/lib/classnames"

interface FormGroupProps extends HTMLAttributes<HTMLDivElement> {
	heading?: ReactNode
	children?: ReactNode
}

export const FormGroup = ({ children, heading, ...props }: FormGroupProps) => (
	<FormGroupContainer {...props}>
		<FormGroupSidebar>{heading}</FormGroupSidebar>
		{children}
	</FormGroupContainer>
)

export const FormGroupContainer = ({
	className,
	...props
}: HTMLProps<HTMLDivElement>) => (
	<div
		className={classNames(
			className,
			"space-y-6 md:grid md:grid-cols-form-group md:gap-6 md:space-y-0",
		)}
		{...props}
	/>
)

export const FormContent = ({
	className,
	...props
}: HTMLProps<HTMLDivElement>) => {
	return <div className={classNames(className, "space-y-6")} {...props} />
}

export const FormGroupSidebar = ({
	className,
	...props
}: HTMLProps<HTMLDivElement>) => {
	return (
		<div
			className={classNames(
				className,
				"space-y-4 text-sm text-gray-500 md:w-3/4 xl:w-4/5",
			)}
			{...props}
		/>
	)
}

import { DetailedHTMLProps, InputHTMLAttributes } from "react"
import { classNames } from "@/lib/classnames"

export type CheckboxProps = Omit<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	"type"
>

export const Checkbox = ({ className = "", ...props }: CheckboxProps) => (
	<input
		className={classNames(
			className,
			"h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500",
		)}
		type="checkbox"
		{...props}
	/>
)

import { ReactNode } from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

// Store
import { store, persistor } from "./store"

// UI
import { LoadingScreen } from "@/screens/LoadingScreens"

interface StateProviderProps {
	children: ReactNode
}

/**
 * StateProvider
 * @param param0
 * @returns
 */
export const StateProvider = function ({ children }: StateProviderProps) {
	return (
		<Provider store={store}>
			<PersistGate
				loading={<LoadingScreen className="min-h-screen" />}
				persistor={persistor}
			>
				{children}
			</PersistGate>
		</Provider>
	)
}

// Export react-redux
export * from "react-redux"

import { Helmet } from "@/lib/seo"
import { Route, Routes } from "react-router-dom"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// Translations
import { useTrans } from "@/i18n"

// Pages
import { Pages } from "@/misc/pages"
import { InvestorProfile } from "./pages/InvestorProfile"
import { InvestorTest } from "./pages/InvestorTest"
import { InvestorFunds } from "./pages/InvestorFunds"
import { InvestorIdentity } from "./pages/InvestorIdentity"
import { InvestorRisk } from "./pages/InvestorRisk"
import { NotFound } from "../NotFound"

// Feature flags
import {
	useFeatureFlags,
	useCurrentUserIdentityType,
	useCurrentUserKyc,
} from "@/context/user"

// GraphQL
import {
	CoreInvestmentTestExperienceChoices,
	RiskKycStateChoices,
	UsersUserProfileIdentityTypeChoices,
} from "@/api/graphql"

// Context
import { useInvestmentTests } from "@/context/investmentTests"

/**
 * InvestorProfile
 * @returns
 */
export function Investor() {
	const t = { investor: useTrans("investor") }
	const { getFeatureFlagValue } = useFeatureFlags()
	const identity_type: UsersUserProfileIdentityTypeChoices =
		useCurrentUserIdentityType()
	const userKyc = useCurrentUserKyc()

	// Context
	const {
		investorProfileExperience,
		investorTestQuestions,
		investorTestRisk,
	} = useInvestmentTests()

	// What nav items do we show?
	const showInvestorIdentity =
		identity_type !== UsersUserProfileIdentityTypeChoices.Business &&
		userKyc.state !== RiskKycStateChoices.Uninitiated

	// Return
	return (
		<>
			<Helmet>
				<title>{t.investor("investor.title")}</title>
			</Helmet>
			<PageTemplate
				title={t.investor("investor.title")}
				tabs={
					<>
						<PageTemplateLink
							to={Pages.InvestorProfile}
							data-testid="investor.profile"
							name={t.investor("investor.profile.title")}
							analyticsId="investor.profile"
							badgeNumber={investorProfileExperience ? 0 : 1}
							badgeVerified={Boolean(investorProfileExperience)}
						/>

						{/** Show if user is NOVICE */}
						{investorProfileExperience?.experience ===
							CoreInvestmentTestExperienceChoices.Novice && (
							<>
								<PageTemplateLink
									to={Pages.InvestorTest}
									data-testid="investor.investor_test"
									name={t.investor("investor.test.title")}
									analyticsId="investor.investor_test"
									badgeNumber={investorTestQuestions ? 0 : 1}
									badgeVerified={Boolean(
										investorTestQuestions,
									)}
								/>
								<PageTemplateLink
									to={Pages.InvestorRisk}
									data-testid="investor.investor_risk"
									name={t.investor("investor.risk.title")}
									analyticsId="investor.investor_risk"
									badgeNumber={investorTestRisk ? 0 : 1}
									badgeVerified={Boolean(investorTestRisk)}
								/>
							</>
						)}

						{getFeatureFlagValue(
							"FEATURE_ENABLE_INVESTOR_FUND_ORIGINS",
						) === true ? (
							<PageTemplateLink
								to={Pages.InvestorFunds}
								data-testid="investor.funds"
								name={t.investor("investor.funds.title")}
								analyticsId="investor.funds"
							/>
						) : null}

						{showInvestorIdentity === true ? (
							<PageTemplateLink
								to={Pages.InvestorIdentity}
								data-testid="investor.indentity"
								name={t.investor("investor.identity.title")}
								analyticsId="investor.identity"
								badgeNumber={
									userKyc.state ===
									RiskKycStateChoices.VerificationSuccess
										? 0
										: 1
								}
								badgeVerified={
									userKyc.state ===
									RiskKycStateChoices.VerificationSuccess
								}
							/>
						) : null}
					</>
				}
			>
				<Routes>
					{/** Nested routes */}
					<Route path="test" element={<InvestorTest />} />
					<Route path="risk" element={<InvestorRisk />} />
					<Route path="funds" element={<InvestorFunds />} />
					<Route path="identity" element={<InvestorIdentity />} />

					{/** Index */}
					<Route path="/" element={<InvestorProfile />} />

					{/** Not found */}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</PageTemplate>
		</>
	)
}

// React
import { Fragment, useMemo } from "react"

// Router
import { useParams } from "@/lib/router"

// Queries
import {
	StatsInformationByInterestPeriodType,
	useSingleProjectStatsInterestPeriodsQuery,
} from "@/api/graphql"

// UI
import { Card } from "@/components/Card"

// Tables
import { CellProps, useTable, UseTableOptions, Row } from "react-table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableRowCell,
	TableHead,
	TableHeading,
} from "@/components/table-controls/TableItems"

// I18n
import { useTrans } from "@/i18n"

/**
 * ProjectSolarInterestPeriodsTable
 * @returns
 */
export function ProjectSolarInterestPeriodsTable() {
	// I18n
	const t = useTrans("dashboard")

	// Router
	const params = useParams()

	// Fetch
	const { data } = useSingleProjectStatsInterestPeriodsQuery({
		id: String(params.id),
	})
	// Memo
	const stats = useMemo(() => {
		return (
			(data?.project_statistics
				?.stats_information_by_interest_period as StatsInformationByInterestPeriodType[]) ||
			[]
		)
	}, [data?.project_statistics?.stats_information_by_interest_period])

	// Table
	const columns = useMemo(() => {
		const cols: UseTableOptions<StatsInformationByInterestPeriodType>["columns"] =
			[
				{
					id: "id",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.period",
							)}
						</TableHeading>
					),
					Cell: ({
						row,
					}: {
						row: Row<StatsInformationByInterestPeriodType>
					}) => <TableDataCell>{row.index}</TableDataCell>,
				},
				{
					accessor: "production_per_share",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.production_per_share",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							{Number(value).toFixed(2)}
						</TableDataCell>
					),
				},
				{
					accessor: "energy_price",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.energy_price",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							€ {Number(value).toFixed(4)}
						</TableDataCell>
					),
				},
				{
					accessor: "solar_interest_per_share",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.solar_interest_per_share",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							€ {Number(value).toFixed(2)}
						</TableDataCell>
					),
				},
				{
					accessor: "payment_per_share",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.payment_per_share",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							€ {Number(value).toFixed(2)}
						</TableDataCell>
					),
				},
				{
					accessor: "return_on_investment",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.return_on_investment",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							{Number(value).toFixed(2)} %
						</TableDataCell>
					),
				},
				{
					accessor: "expected_production_per_share",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_production_per_share",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							{Number(value).toFixed(2)}
						</TableDataCell>
					),
				},
				{
					accessor: "expected_solar_interest_per_share",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_solar_interest_per_share",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							€ {Number(value).toFixed(2)}
						</TableDataCell>
					),
				},
				{
					accessor: "expected_payment_per_share",
					Header: () => (
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_payment_per_share",
							)}
						</TableHeading>
					),
					Cell: ({ value }) => (
						<TableDataCell>
							€ {Number(value).toFixed(2)}
						</TableDataCell>
					),
				},
			]

		return cols.filter(Boolean)
	}, [])
	const {
		getTableProps,
		getTableBodyProps,
		rows,
		headerGroups,
		state: { pageIndex },
		prepareRow,
	} = useTable({
		columns,
		data: stats,
		defaultColumn: {
			Cell: ({
				value,
			}: CellProps<
				StatsInformationByInterestPeriodType,
				string | number
			>) => <TableDataCell>{value}</TableDataCell>,
		},
	})

	return (
		<Card className="row mt-5">
			<Table
				{...getTableProps({
					className: "min-w-[64rem] lg:min-w-0",
				})}
				data-testid="tablebody"
			>
				<TableHead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => {
								return (
									<Fragment key={column.getHeaderProps().key}>
										{column.render("Header")}
									</Fragment>
								)
							})}
						</tr>
					))}
				</TableHead>

				<TableBody
					{...getTableBodyProps()}
					data-testid="tablebody-overview"
					data-pageindex={pageIndex}
				>
					{rows.map((row) => {
						prepareRow(row)

						const isOdd = row.index % 2 === 0
						return (
							<Fragment key={row.id}>
								<TableRowCell
									isOdd={isOdd}
									variant={
										row.original?.is_current_interest_period
											? "neutral"
											: row.original
													?.is_completed_interest_period
											? "valid"
											: "invalid"
									}
								>
									{row.cells?.map((cell, index) => {
										return (
											<Fragment key={index}>
												{cell.render("Cell")}
											</Fragment>
										)
									})}
								</TableRowCell>
							</Fragment>
						)
					})}
				</TableBody>
			</Table>
		</Card>
	)
}

/**
 * ProjectSolarInterestPeriodsTableLoading
 * @returns
 */
export function ProjectSolarInterestPeriodsTableLoading() {
	const t = useTrans("dashboard")

	return (
		<Card className="row mt-5">
			<Table className="min-w-[64rem] lg:min-w-0">
				<TableHead>
					<tr>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.period",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.production_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.energy_price",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.solar_interest_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.payment_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.return_on_investment",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_production_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_solar_interest_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_payment_per_share",
							)}
						</TableHeading>
						<TableHeading>
							{t(
								"dashboard.stats.project_stats.table.interest_periods.expected_interest",
							)}
						</TableHeading>
					</tr>
				</TableHead>
				<TableBody>
					<TableRowCell>
						<TableDataCell>-</TableDataCell>
					</TableRowCell>
				</TableBody>
			</Table>
		</Card>
	)
}

import { Suspense } from "react"

// SEO
import { Helmet } from "@/lib/seo"
import { Route, Routes } from "react-router-dom"
import { useTrans } from "@/i18n"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"
import { useCurrentProject } from "./hooks"

// Pages
import { Pages } from "@/misc/pages"
import { NotFound } from "@/pages/NotFound"
import { Message } from "./pages/Message"
import { ProjectCommunications } from "./pages/Communications"
import {
	ProjectDashboard,
	ProjectDashboardLoadingState,
} from "./pages/ProjectDashboard"
import { ProjectDocumentation } from "./pages/Documentation"
import { ProjectNotifications } from "./pages/Notifications"
import { ProjectProduction } from "./pages/Production"
import { ProjectRevenue } from "./pages/Revenue"
import { ProjectNotFound } from "./ProjectNotFound"

// Queries
import { ProjectStateEnum } from "@/api/graphql"

// Feature flags
import { useFeatureFlags } from "@/context/user"

export const Project = () => {
	const { getFeatureFlagValue } = useFeatureFlags()
	const { data, refetch } = useCurrentProject()
	const t = useTrans("project")

	if (data?.project === null) {
		return <ProjectNotFound onReset={refetch} />
	}

	return (
		<>
			<Helmet>
				<title>{data?.project?.name}</title>
			</Helmet>
			<PageTemplate
				title={data?.project?.name ?? ""}
				backHref={Pages.InvestmentsProjectsDashboard}
				tabs={
					<>
						<PageTemplateLink
							to="./dashboard"
							end
							data-testid="project.dashboard"
							name={t("project.dashboard.title")}
							analyticsId="project.dashboard"
						/>
						<PageTemplateLink
							to="./revenue"
							data-testid="project.revenue"
							name={t("project.revenue.title")}
							analyticsId="project.revenue"
						/>

						{/** Show production when project has Production data */}
						{data?.project.state ===
						ProjectStateEnum.InProduction ? (
							<PageTemplateLink
								to="./data"
								data-testid="project.data"
								name={t("project.data.title")}
								analyticsId="project.data"
							/>
						) : null}

						<PageTemplateLink
							to="./notifications"
							data-testid="project.notifications"
							name={t("project.notifications.title")}
							analyticsId="project.notifications"
						/>
						{getFeatureFlagValue(
							"FEATURE_ENABLE_PROJECT_TIMELINE",
						) === true && (
							<PageTemplateLink
								to="./messages"
								data-testid="project.communications"
								name={t("project.communications.title")}
								analyticsId="project.communications"
							/>
						)}

						<PageTemplateLink
							to="./documentation"
							data-testid="project.documentation"
							name={t("project.documentation.title")}
							analyticsId="project.documentation"
						/>
					</>
				}
			>
				<Routes>
					<Route path="revenue" element={<ProjectRevenue />} />
					<Route
						path="notifications"
						element={<ProjectNotifications />}
					/>
					<Route path="data" element={<ProjectProduction />} />
					<Route
						path="messages"
						element={<ProjectCommunications />}
					/>
					<Route path="messages/:id" element={<Message />} />
					<Route
						path="documentation"
						element={<ProjectDocumentation />}
					/>

					<Route
						path="dashboard"
						element={
							<Suspense
								fallback={<ProjectDashboardLoadingState />}
							>
								<ProjectDashboard />
							</Suspense>
						}
					/>
					<Route path="*" element={<NotFound />} />
				</Routes>
			</PageTemplate>
		</>
	)
}

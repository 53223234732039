// React
import { Fragment, useMemo, ReactNode, useState } from "react"

// Router
import { Link } from "@/lib/router"
import { Pages } from "@/misc/pages"

// Dates
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/misc/constants"

// Translations
import { useTrans } from "@/i18n"
import { useLang } from "@/context/lang"
import { emptyValue } from "@/misc/helpers"

// Queries
import { ProjectType, useAllInvestorPaymentsQuery } from "@/api/graphql"

// Tables
import {
	CellProps,
	ColumnInstance,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"

// UI
import { classNames } from "@/lib/classnames"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"

// Utils
import { calculatePlannedPayoutDate } from "../../../project/pages/Revenue"

// Types
import { PaymentEntryTypeExtended, getPayoutStateColor } from "../../Payments"

/*
 * PayoutsTable on webpage
 * @returns
 */
export default function PayoutsTableWeb() {
	// Translations
	const t = useTrans("project")
	const { formatCurrency } = useLang()

	// State
	const limit = 10
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Queries
	const { data } = useAllInvestorPaymentsQuery({
		limit,
		offset: currentPage * limit,
		ordering: "-payment__interestperiod__end", // Uses internal python model structure
	})

	// Memo
	const investments = useMemo(() => {
		return data?.me?.payment_entries?.results?.map((payment, index) => {
			const interestPeriodEndDate = DateTime.fromISO(
				payment?.interest_period?.end,
			)

			const paymentPlannedPayoutDate = calculatePlannedPayoutDate({
				isFirstPeriod: index === 0, // First payment. TODO: Work with pagination
				interestPeriodEndDate,
				paymentPeriodDeadlineDaysFirstPeriodDelay:
					payment?.project
						?.payment_period_deadline_days_first_period_delay,
				paymentPeriodDeadlineDaysDefault:
					payment?.project?.payment_period_deadline_days_default,
			})

			// Return payments
			return {
				...payment,
				planned_payout_date: paymentPlannedPayoutDate,
				state_color: getPayoutStateColor(
					payment?.state,
					paymentPlannedPayoutDate,
				),
			}
		})
	}, [data?.me?.payment_entries?.results])

	// PaginatorInfo
	const totalPayoutCount = useMemo(
		() => data?.me?.payment_entries?.totalCount ?? 0,
		[data?.me?.payment_entries?.totalCount],
	)

	// Table Data
	const columns = useMemo(() => {
		const cols: UseTableOptions<PaymentEntryTypeExtended>["columns"] = [
			{
				id: "interest_period.start",
				accessor: (data) => data.interest_period?.start,
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.interest_period",
						)}
					</DefaultTableHeader>
				),
			},
			{
				id: "planned_payout_date",
				accessor: (data) =>
					data?.planned_payout_date.toFormat(dateFormat) ||
					emptyValue(),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("project.revenue.table.headers.planned-payout-date")}
					</DefaultTableHeader>
				),
			},
			{
				id: "project.name",
				accessor: (data) => data?.project,
				Cell: ({ value }: { value: ProjectType }) => (
					<TableDataCell className="font-medium">
						<Link
							to={`${Pages.InvestmentsProjects}/${value?.id}/revenue`}
						>
							{value?.name}
						</Link>
					</TableDataCell>
				),
				Header: () => (
					<TableHeading variant="static">
						{t("project:project.revenue.table.headers.project")}
					</TableHeading>
				),
			},
			{
				id: "paymentSum",
				accessor: (data) =>
					Number(data?.cost) +
					Number(data?.amortization) +
					Number(data?.repayment),
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t("project:project.revenue.table.headers.payment")}
					</DefaultTableHeader>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{value ? formatCurrency(value) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "transaction_id",
				accessor: (data) => data?.transaction_id,
				Cell: ({ value }: { value: string }) => (
					<TableDataCell className="font-medium">
						{value}
					</TableDataCell>
				),
				Header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					</TableHeading>
				),
			},
			{
				id: "project.type",
				accessor: (data) => data?.project?.type,
				Header: ({ column }) => (
					<DefaultTableHeader column={column}>
						{t(
							"project:project.revenue.table.headers.project_type",
						)}
					</DefaultTableHeader>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{t(`common:common.project.type.${value}`)}
					</TableDataCell>
				),
			},
		]

		return cols.filter(Boolean)
	}, [])
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		state: { pageSize, pageIndex },
		prepareRow,
		visibleColumns,
	} = useTable(
		{
			columns,
			data: investments as PaymentEntryTypeExtended[],
			defaultColumn: {
				Cell: ({
					value,
				}: CellProps<PaymentEntryTypeExtended, string | number>) => (
					<TableDataCell className="break-word whitespace-pre-wrap">
						{value}
					</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	)

	return (
		<>
			{/** When user has no payouts */}
			{totalPayoutCount === 0 && (
				<Card>
					<p className="text-sm text-gray-500">
						<div className="z-2 relative px-8">
							<Heading as="h4" className="text-center">
								{t("project:project.revenue.no-data.title")}
							</Heading>
							<p className="mt-2 text-center text-sm text-gray-500">
								{t("project:project.revenue.no-data.copy")}
							</p>
						</div>
					</p>
				</Card>
			)}

			{/** Show table if we have payouts */}
			{totalPayoutCount !== 0 && (
				<Table
					{...getTableProps({
						className: "min-w-[64rem] lg:min-w-0 mb-5",
					})}
					data-testid="tablebody"
				>
					<TableHead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => {
									const { key } = column.getHeaderProps()

									return (
										<Fragment key={key}>
											{column.render("Header")}
										</Fragment>
									)
								})}
							</tr>
						))}
					</TableHead>
					<TableBody
						{...getTableBodyProps()}
						data-testid="tablebody-overview"
						data-pageindex={pageIndex}
					>
						{page.map((row) => {
							prepareRow(row)

							const isOdd = row.index % 2 === 0
							let className = ""

							return (
								<Fragment key={row.id}>
									<TableRowCell
										isOdd={isOdd}
										variant={row.original.state_color}
										className={classNames(className)}
									>
										{row.cells.map((cell) => {
											const { key } = cell.getCellProps({
												className:
													cellClassNameMap[
														cell.column.id
													],
											})
											return (
												<Fragment key={key}>
													{cell.render("Cell")}
												</Fragment>
											)
										})}
									</TableRowCell>
									<TableRowCell
										isOdd
										withHover={false}
										className="p-0"
									>
										<td
											colSpan={visibleColumns.length}
											className="p-0"
										></td>
									</TableRowCell>
								</Fragment>
							)
						})}
						{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
						{page.length < pageSize && pageIndex !== 0 ? (
							<>
								{Array(Math.max(pageSize - page.length, 1))
									.fill(true)
									.map((_, index) => (
										<TableRowCell
											key={index}
											withHover={false}
											isOdd={index % 2 === 0}
										>
											<TableDataCell
												colSpan={columns.length}
											>
												<span className="dummy-text" />
											</TableDataCell>
										</TableRowCell>
									))}
							</>
						) : null}
					</TableBody>
				</Table>
			)}

			{/** Show pagination */}
			{investments?.length !== 0 && (
				<PaginationAsButtons
					countPerPage={limit}
					totalCount={totalPayoutCount ?? 0}
					itemType={"common.pagination.item_types.payment"}
					currentPage={currentPage + 1}
					currentItemsAmount={investments?.length ?? 0}
					onNextPage={() =>
						setCurrentPage((currentPage) => currentPage + 1)
					}
					onPrevPage={() =>
						setCurrentPage((currentPage) =>
							Math.max(currentPage - 1, 0),
						)
					}
					analyticsId="investments"
				/>
			)}
		</>
	)
}

const cellClassNameMap: { [key: string]: string } = {
	expander: "w-10",
}
const DefaultTableHeader = ({
	column,
	children,
}: {
	column: ColumnInstance<PaymentEntryTypeExtended>
	children: ReactNode
}) => {
	return (
		<TableHeading
			{...column.getHeaderProps(
				column.getSortByToggleProps({
					// @ts-ignore
					isSorted: column.isSorted,
					isSortedDesc: column.isSortedDesc,
				}),
			)}
		>
			{children}
		</TableHeading>
	)
}

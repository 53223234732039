// Translations
import { useTrans } from "@/i18n"

// Env variables
const EXTERNAL_PLATFORM_INFO_CENTER =
	process.env.REACT_APP_EXTERNAL_PLATFORM_INFO_CENTER

/**
 * Footer
 */
export function Footer() {
	const t = useTrans()

	return (
		<div className="flex-1 px-4 pb-6 sm:px-7">
			<div className="flex max-w-7xl">
				<div className="flex-1">
					{" "}
					&copy; {t("common.brand.name")} 2024
				</div>
				<a
					href={EXTERNAL_PLATFORM_INFO_CENTER}
					target="_blank"
					className="flex-1 text-right font-medium text-secondary-300 hover:text-secondary-700"
					rel="noreferrer"
				>
					{t("common.layout.footer.faq")}
				</a>
			</div>
		</div>
	)
}

export interface LangConfig {
	[key: string]: string
	path: string
	display: string
	locale: string
	currency: string
	siteLanguage: string
	textDirection: string
	title: string
	defaultTitle: string
	dateFormat: string
	dateFormatMonthAndDay: string
	projectCardInterestPeriodDateFormat: string
}

export const defaultLanguage = "nl"
export type Languages = "en" | "nl"
export const supportedLanguages = new Set<Languages>(["en", "nl"])

export type LangConfigs = {
	[K in Languages]: LangConfig
}

const configs: LangConfigs = {} as LangConfigs

const loadConfigs = (config: LangConfigs) => {
	const renameKeys = (obj: Record<string, string>): LangConfig =>
		Object.keys(obj).reduce(
			(acc, key) => ({
				...acc,
				...{ [key.replace("config.", "")]: obj[key] },
			}),
			{} as any,
		)

	supportedLanguages.forEach((language) => {
		const localConfigRaw = require(`../../src/i18n/locales/${language}/config.json`)
		const localConfig = renameKeys(localConfigRaw)
		config[language] = localConfig
	})
}

loadConfigs(configs)

export { configs }

// Translations
import { useTrans } from "@/i18n"

// GraphQL
import { useChangePasswordMutation } from "@/api/graphql"

// Forms
import { FormikProvider, useFormik } from "formik"
import { handleErrorWithFormik } from "@/lib/formik"
import { FormikError } from "../form-controls/Errors"
import { FormGroup } from "../form-controls/FormGroup"
import {
	FormikErrors,
	FormikInput,
	FormikLabel,
	FormikSubmitButton,
} from "../form-controls/formik"
import { Yup } from "@/lib/yup"
import { LabelSubtitle } from "../form-controls/Label"

// UI
import { Heading } from "../Typography"
import { CardBody, CardFooter, CardWrapper } from "../Card"
import { useToasts } from "@/context/toasts"

/**
 * ChangePassword
 * @returns
 */
export const ChangePassword = () => {
	const toasts = useToasts()
	const t = useTrans("profile")

	// Mutation
	const changePasswordMutation = useChangePasswordMutation({
		onSuccess: async (response) => {
			// map each error from graphql to the field it's related to
			for (const error of response?.password_reset?.errors ?? []) {
				if (error?.field) {
					form.setFieldError(error.field, error.messages[0])
				}

				// Handle incorrect password error
				if (error?.field === "nonFieldErrors") {
					form.setFieldError(
						"errors.local",
						"profile.change_password.form_errors.wrong_password",
					)
				}
			}

			// do we have data? then success
			if (
				!response?.password_reset?.errors ||
				response.password_reset.errors?.length === 0
			) {
				toasts.addToast({
					variant: "success",
					id: `change-password-success-${Date.now()}`,
					text: t(`profile.change_password.success_message`),
				})
			}
			// otherwise show a message that there are form errors
			else {
				form.setFieldError(
					"errors.common",
					"common.form_errors.unknown_error",
				)
			}
		},
		onError: (error: Error) => {
			handleErrorWithFormik(error, form)
		},
	})

	// Form
	const form = useFormik({
		initialValues: {
			password: "",
			newPassword: "",
			newPasswordConfirmation: "",
			errors: { local: "", common: "" },
		},
		validationSchema,
		onSubmit: async (values, helpers) => {
			await changePasswordMutation.mutateAsync({
				input: {
					current_password: values?.password ?? "",
					new_password: values?.newPassword ?? "",
					confirm_password: values?.newPasswordConfirmation ?? "",
				},
			})
		},
	})

	return (
		<FormikProvider value={form}>
			<CardWrapper>
				<form onSubmit={form.handleSubmit}>
					<CardBody>
						<Heading
							as="h2"
							styleAs="h5"
							className="mb-3 sm:truncate"
							data-testid={"change-password-heading"}
						>
							{t("profile.change_password.title")}
						</Heading>
						<FormGroup
							heading={<p>{t("profile.change_password.copy")}</p>}
						>
							<div className="space-y-6">
								<div>
									<FormikLabel htmlFor="password">
										{t(
											"profile.change_password.password.title",
										)}
										<LabelSubtitle>
											{t(
												"profile.change_password.password.copy",
											)}
										</LabelSubtitle>
									</FormikLabel>
									<FormikInput
										name="password"
										type="password"
										required
										autoComplete="current-password"
										className="block w-full"
										classNameWrapper="lg:w-1/2"
										aria-label={t(
											"profile.change_password.password.title",
										)}
										placeholder={t(
											"profile.change_password.password.placeholder",
										)}
									/>
								</div>
								<div>
									<FormikLabel htmlFor="newPassword">
										{t(
											"profile.change_password.new_password.title",
										)}
										<LabelSubtitle>
											{t(
												"profile.change_password.new_password.copy",
											)}
										</LabelSubtitle>
									</FormikLabel>
									<FormikInput
										name="newPassword"
										type="password"
										required
										autoComplete="new-password"
										className="block w-full"
										classNameWrapper="lg:w-1/2"
										aria-label={t(
											"profile.change_password.new_password.title",
										)}
										placeholder={t(
											"profile.change_password.new_password.placeholder",
										)}
									/>
									<FormikError
										field="newPassword"
										reliesOnField="newPasswordConfirmation"
										namespace="profile"
									/>
								</div>
								<div>
									<FormikLabel htmlFor="newPasswordConfirmation">
										{t(
											"profile.change_password.new_password_confirmation.title",
										)}
									</FormikLabel>
									<FormikInput
										name="newPasswordConfirmation"
										type="password"
										required
										autoComplete="new-password"
										className="block w-full"
										classNameWrapper="lg:w-1/2"
										aria-label={t(
											"profile.change_password.new_password_confirmation.title",
										)}
										placeholder={t(
											"profile.change_password.new_password_confirmation.placeholder",
										)}
									/>
									<FormikError
										field="newPasswordConfirmation"
										namespace="profile"
									/>
								</div>
								<div>
									<FormikErrors i18nNamespace="profile" />
								</div>
							</div>
						</FormGroup>
					</CardBody>
					<CardFooter className="text-right">
						<FormikSubmitButton>
							{t("profile.change_password.form_actions.save")}
						</FormikSubmitButton>
					</CardFooter>
				</form>
			</CardWrapper>
		</FormikProvider>
	)
}

const validationSchema = Yup.object().shape({
	password: Yup.string().required(
		"profile.change_password.form_errors.password.required",
	),
	newPassword: Yup.string()
		.min(8, "profile.change_password.form_errors.password.min_8_chars")
		.oneOf(
			[Yup.ref("newPasswordConfirmation")],
			"profile.change_password.form_errors.passwords_do_not_match",
		)
		.required("profile.change_password.form_errors.new_password.required"),
	newPasswordConfirmation: Yup.string()
		.oneOf(
			[Yup.ref("newPassword")],
			"profile.change_password.form_errors.passwords_do_not_match",
		)
		.required(
			"profile.change_password.form_errors.new_password_confirmation.required",
		),
})

import { availableLanguageFiles, availableLanguages } from "@/misc/constants"
const localeData: { [key: string]: boolean } = {}

availableLanguageFiles.forEach((namespace) => {
	availableLanguages.forEach((lang) => {
		const pathLocal = `./${lang}/${namespace}.json`
		const pathAbsolute = `i18n/locales/${lang}/${namespace}.json`
		localeData[pathLocal] = true
		localeData[pathAbsolute] = true
	})
})

const loadLocales = (lang: string, namespace: string) => {
	// do not try and load chunk if it doesn't exist
	if (!localeData[`./${lang}/${namespace}.json`]) {
		return {}
	}
	try {
		return import(`./locales/${lang}/${namespace}.json`).then(
			(module) => module.default,
		)
	} catch {
		return {}
	}
}

export { localeData, loadLocales }

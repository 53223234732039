import { Helmet } from "@/lib/seo"
import { useTrans } from "@/i18n"

// Forms
import { InvestorIdentityForm } from "../forms/InvestorIdentityForm"

/**
 * InvestorIdentity
 * @returns
 */
export const InvestorIdentity = () => {
	const t = { investor: useTrans("investor") }

	return (
		<>
			<Helmet>
				<title>{t.investor("investor.identity.title")}</title>
			</Helmet>

			<InvestorIdentityForm />
		</>
	)
}

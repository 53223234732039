import { ChangeEvent, useState, useMemo } from "react"

import { Helmet } from "@/lib/seo"
import { ApiErrorResponseInterface } from "@/api/rest"

import { Input } from "@/components/form-controls/Input"
import { Label } from "@/components/form-controls/Label"
import { Heading } from "@/components/Typography"
import { SingleScreenLayout } from "../../layouts/SingleScreenLayout"
import { sendEvent } from "@/lib/analytics"

// Routes
import { Pages } from "@/misc/pages"
import { Link, useNavigate } from "react-router-dom"

// UI
import { classNames } from "@/lib/classnames"
import { Button } from "@/components/Button"
import { Card } from "@/components/Card"

// Forms
import { FormikProvider, useFormik } from "formik"
import { Yup } from "@/lib/yup"
import { Checkbox } from "@/components/form-controls/Checkbox"
import {
	FormikInput,
	FormikRadio,
	FormikErrors,
	FormikError,
	FormikSubmitButton,
	FormikLabel,
} from "@/components/form-controls/formik"

// Queries
import {
	UsersUserProfileIdentityTypeChoices,
	CoreLocationCountryChoices,
	UsersUserProfileNationalityChoices,
} from "@/api/graphql"
import { apiAuthUsersCreate } from "@/api/rest/generated/api/api"

// Animations
import { Popover, Transition } from "@headlessui/react"
import { getTransitionPopoverProps } from "@/lib/animations"

// Translations
import { Trans, useTrans } from "@/i18n"
import { onlyCoreLocationCountryChoices } from "@/lib/countries"
import { useLang } from "@/context/lang"

// Forms
import { Select, SelectButton } from "@/components/form-controls"

// UI
import { DatePicker } from "@/components/form-controls/DatePicker"

// Icons
import { FiChevronDown } from "react-icons/fi"

// Images
import IconZPD from "@/assets/icons/zpd/logo.svg"

/**
 * Register
 * @returns
 */
export const Register = () => {
	const t = useTrans("register")

	return (
		<>
			<Helmet>
				<title>{t("register.title")}</title>
			</Helmet>
			<SingleScreenLayout
				content={
					<>
						<div className="text-center">
							<picture>
								<img
									src={IconZPD}
									className="mx-auto h-12"
									alt=""
								/>
							</picture>
							<Heading styleAs="h3" className="mt-6">
								{t("register.title")}
							</Heading>
						</div>
						<Card className="mt-6" variant="gray">
							<RegisterForm />
						</Card>
						<Card className="mt-6 text-sm">
							<p>
								<Trans
									ns="register"
									i18nKey="register.already_have_an_account_no_account_prompt"
									components={{
										Link: (
											<Link
												to={Pages.Login}
												className="font-medium text-secondary-300 hover:text-secondary-700"
											/>
										),
									}}
								/>
							</p>
						</Card>
					</>
				}
			/>
		</>
	)
}

const NUMBER_OF_AVAILABLE_STEPS = 3

/**
 * RegisterForm
 * @returns
 */
const RegisterForm = () => {
	const navigate = useNavigate()

	// Translations
	const t = useTrans("register")
	const { getCountryName } = useLang()

	// State
	const [currentStepNumber, setCurrentStepNumber] = useState<number>(1)

	const form = useFormik({
		validationSchema: Yup.object().shape({
			name: Yup.string().required(
				"register.form_errors.first-name.required",
			),
			email: Yup.string()
				.email("register.form_errors.email.invalid")
				.required("register.form_errors.email.required"),
			password: Yup.string().required(
				"register.form_errors.password.required",
			),
			passwordConfirmation: Yup.string()
				.oneOf(
					[Yup.ref("password")],
					"register.form_errors.password_confirmation.must_match",
				)
				.required(
					"register.form_errors.password_confirmation.required",
				),
			termsAndConditions: Yup.bool().oneOf(
				[true],
				"register.form_errors.terms_and_conditions.requried",
			),
		}),
		initialValues: {
			identity_type:
				UsersUserProfileIdentityTypeChoices.Private as UsersUserProfileIdentityTypeChoices,
			first_name: "",
			last_name: "",
			email: "",
			organisation: {
				business_nr: "",
			},
			profile: {
				phone: "",
				initials: "",
				date_of_birth: "",
				nationality: "" as UsersUserProfileNationalityChoices,
				address: {
					address_line_primary: "", // Straat, Postbus, Bedrijfsnaam, t.a.v. etc.
					address_line_secondary: "", // Huisnummer toevoeging
					city: "",
					country: "NL" as CoreLocationCountryChoices,
					postal_code: "",
				},
			},
			iban: "",
			password: "",
			passwordConfirmation: "",
			termsAndConditions: false,
			termsAndConditionsPSP: false,
			errors: { local: "", common: "" },
		},
		onSubmit: async (values, helpers) => {
			sendEvent("register", "submit")
			try {
				const response = await apiAuthUsersCreate({
					email: values.email,
					password: values.password,
					re_password: values.passwordConfirmation,
				})

				if (!response) {
					throw new Error("No response")
				}

				sendEvent("register", "submit-success")
				navigate("/confirm")
			} catch (e) {
				sendEvent("register", "submit-error")
				const error = e as ApiErrorResponseInterface | null

				if (error?.json?.message === "duplicate_user_registration") {
					helpers.setFieldError(
						"errors.local",
						"register.form_errors.duplicate_user_registration",
					)
				} else if (error?.json?.message) {
					helpers.setFieldError("errors.local", error?.json?.message)
				} else {
					helpers.setFieldError(
						"errors.common",
						"common.form_errors.unknown_error",
					)
				}
			}
		},
	})

	const handleChangeWithAnalytics = (e: ChangeEvent<any>) => {
		sendEvent("register", `onchange-${e.currentTarget.name}`)
		return form.handleChange(e)
	}

	const sortedCountries = useMemo(() => {
		return onlyCoreLocationCountryChoices
			.map((country) => ({
				...country,
				localisedCountry: getCountryName(country.code) ?? country.name,
			}))
			.sort((a, b) =>
				a.localisedCountry.localeCompare(b.localisedCountry),
			)
	}, [getCountryName])

	return (
		<FormikProvider value={form}>
			<form onSubmit={form.handleSubmit} className="space-y-6">
				<div className="flex">
					{Array.from(
						{ length: NUMBER_OF_AVAILABLE_STEPS },
						(_, index) => index + 1,
					).map((number) => (
						<div
							className={classNames(
								currentStepNumber === number &&
									"text-primary-500",
							)}
						>
							{number}
						</div>
					))}
				</div>

				{/** Step 1: Personal details */}
				{currentStepNumber === 1 && (
					<div>
						<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
							{t("register.form.personal-details.title")}
						</Heading>
						<div>
							{/** First Name */}
							<div>
								<FormikLabel
									required
									htmlFor="first_name"
									className="block truncate"
									hasError={
										!!form.errors.first_name &&
										form.touched.first_name
									}
									data-testid="register.first-name.label"
								>
									{t("register.form_field.first-name.label")}
								</FormikLabel>
								<div>
									<FormikInput
										name="first_name"
										autoComplete="first_name"
										required
										className="block w-full"
										placeholder={t(
											"register.form_field.first-name.placeholder",
										)}
										hasError={
											!!form.errors.first_name &&
											form.touched.first_name
										}
										data-testid="register.first_name"
										aria-label="first_name"
										autoFocus
									/>
									<FormikError
										field="first_name"
										namespace="register"
									/>
								</div>
							</div>
						</div>
						<div>
							<div>
								<FormikLabel
									required
									htmlFor="profile.initials"
									className="block truncate"
									hasError={
										!!form.errors.profile?.initials &&
										form.touched.profile?.initials
									}
									data-testid="register.profile.initials.label"
								>
									{t(
										"register.form_field.profile.initials.label",
									)}
								</FormikLabel>
								<div>
									<FormikInput
										name="profile.initials"
										autoComplete="initials"
										required
										className="block w-full"
										hasError={
											!!form.errors.profile?.initials &&
											form.touched.profile?.initials
										}
										data-testid="register.profile.initials"
										aria-label="initials"
									/>
									<FormikError
										field="profile.initials"
										namespace="register"
									/>
								</div>
							</div>
						</div>
						<div>
							<div>
								<FormikLabel
									required
									htmlFor="last_name"
									className="block truncate"
									hasError={
										!!form.errors.last_name &&
										form.touched.last_name
									}
									data-testid="register.last_name.label"
								>
									{t("register.form_field.last-name.label")}
								</FormikLabel>
								<div>
									<FormikInput
										name="last_name"
										autoComplete="last_name"
										required
										className="block w-full"
										hasError={
											!!form.errors.last_name &&
											form.touched.last_name
										}
										data-testid="register.last_name"
										aria-label="last_name"
									/>
									<FormikError
										field="last_name"
										namespace="register"
									/>
								</div>
							</div>
						</div>
						<div>
							<Popover.Group className="2xl:col-span-2">
								<Popover className="relative z-20">
									<Popover.Button as="div">
										<FormikLabel
											required
											htmlFor="profile.date_of_birth"
											className="flex"
										>
											<span className="flex items-center">
												{t(
													"register.form_field.profile.date_of_birth",
												)}
											</span>
										</FormikLabel>
										<FormikInput
											name="profile.date_of_birth"
											value={
												form?.values?.profile
													?.date_of_birth
													? new Date(
															form?.values?.profile?.date_of_birth,
													  ).toLocaleDateString(
															"nl-NL",
													  )
													: undefined
											}
											required
											className="mt-1 block w-full"
											classNameWrapper="lg:w-1/2"
										/>
										<FormikError field="profile.date_of_birth" />
									</Popover.Button>

									<Transition
										{...getTransitionPopoverProps()}
									>
										<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
											{({ close }) => (
												<div
													className="min-w-[300px]"
													data-testid="daterangepicker-from"
												>
													<DatePicker
														showNavigation={false}
														onSelectDate={(
															date,
														) => {
															const formattedDate = `${date?.day}-${date?.month}-${date?.year}`
															form.setFieldValue(
																"profile.date_of_birth",
																formattedDate,
															)

															// Close popover
															close()
														}}
													/>
												</div>
											)}
										</Popover.Panel>
									</Transition>
								</Popover>
							</Popover.Group>
						</div>
						<Button
							onClick={() => setCurrentStepNumber(2)}
							className="mt-4"
						>
							{t("register.form_action.next")}
						</Button>
					</div>
				)}

				{/** Step 2: Identity Type */}
				{currentStepNumber === 2 && (
					<div>
						<div>
							<FormikLabel className="mb-2 block" required>
								{t("register.form.address.identity-type.label")}
							</FormikLabel>
							<div className="flex">
								<FormikRadio
									name={"identity_type"}
									value={
										UsersUserProfileIdentityTypeChoices.Private
									}
									id={String(
										`identity_type-${UsersUserProfileIdentityTypeChoices.Private}`,
									)}
									className="mr-2"
								/>
								<FormikLabel
									htmlFor={String(
										`identity_type-${UsersUserProfileIdentityTypeChoices.Private}`,
									)}
								>
									{t(
										"register.form.address.identity-type.option.private",
									)}
								</FormikLabel>
							</div>
							<div className="flex">
								<FormikRadio
									name={"identity_type"}
									value={
										UsersUserProfileIdentityTypeChoices.Business
									}
									id={String(
										`identity_type-${UsersUserProfileIdentityTypeChoices.Business}`,
									)}
									className="mr-2"
								/>
								<FormikLabel
									htmlFor={String(
										`identity_type-${UsersUserProfileIdentityTypeChoices.Business}`,
									)}
								>
									{t(
										"register.form.address.identity-type.option.business",
									)}
								</FormikLabel>
							</div>
						</div>

						{/** Identity type: Private */}
						{form.values.identity_type ===
							UsersUserProfileIdentityTypeChoices.Private && (
							<div>
								<Heading
									as="h3"
									styleAs="h6"
									className="mr-2 lg:mr-0"
								>
									{t("register.form.address.title")}
								</Heading>
								<div>
									{/** Country */}
									<div className="2xl:col-span-2">
										<FormikLabel
											required
											htmlFor="profile.address.country"
											className="truncate"
										>
											{t("register.form.address.country")}
										</FormikLabel>
										<label className="mt-1">
											<SelectButton className="relative lg:w-1/2">
												{getCountryName(
													form.values.profile?.address
														?.country as CoreLocationCountryChoices,
												)}
												<FiChevronDown className="ml-2" />
												<Select
													onChange={(evt) => {
														evt.preventDefault()
														form.setFieldValue(
															"profile.address.country",
															evt.currentTarget
																.value,
														)
													}}
													name="profile.address.country"
													id="profile.address.country"
													value={
														form.values.profile
															?.address
															?.country ?? ""
													}
												>
													{sortedCountries.map(
														(item) => (
															<option
																value={
																	item.code
																}
																key={item.code}
															>
																{
																	item.localisedCountry
																}
															</option>
														),
													)}
												</Select>
											</SelectButton>
											<FormikError field="profile.address.country" />
										</label>
									</div>
								</div>
								<div>
									{t(
										"register.form.address.country.sublabel",
									)}
								</div>
								<div>
									{/** Nationality */}
									<div className="2xl:col-span-2">
										<FormikLabel
											required
											htmlFor="profile.nationality"
											className="truncate"
										>
											{t(
												"register.form.address.form_field.profile.nationality",
											)}
										</FormikLabel>
										<label className="mt-1">
											<SelectButton className="relative lg:w-1/2">
												{getCountryName(
													form.values.profile
														?.nationality,
												)}
												<FiChevronDown className="ml-2" />
												<Select
													onChange={(evt) => {
														evt.preventDefault()
														form.setFieldValue(
															"profile.nationality",
															evt.currentTarget
																.value,
														)
													}}
													name="profile.nationality"
													id="profile.nationality"
													value={
														form.values.profile
															?.nationality ?? ""
													}
												>
													{sortedCountries.map(
														(item) => (
															<option
																value={
																	item.code
																}
																key={item.code}
															>
																{
																	item.localisedCountry
																}
															</option>
														),
													)}
												</Select>
											</SelectButton>
											<FormikError field="profile.nationality" />
										</label>
									</div>
								</div>
								<div>
									{/** Street name (primary) */}
									<div>
										<FormikLabel
											required
											htmlFor="profile.address.address_line_primary"
											className="block truncate"
											hasError={
												!!form.errors.profile?.address
													?.address_line_primary &&
												form.touched.profile?.address
													?.address_line_primary
											}
											data-testid="register.profile.address.address_line_primary.label"
										>
											{t(
												"register.form.address.form_field.address_line_primary.label",
											)}
										</FormikLabel>
										<div>
											<FormikInput
												name="profile.address.address_line_primary"
												autoComplete="address_line_primary"
												required
												className="block w-full"
												hasError={
													!!form.errors.profile
														?.address
														?.address_line_primary &&
													form.touched.profile
														?.address
														?.address_line_primary
												}
												data-testid="register.profile.address.address_line_primary"
												aria-label="address_line_primary"
											/>
											<FormikError
												field="profile.address.address_line_primary"
												namespace="register"
											/>
										</div>
									</div>
								</div>
								{/** Housenumber and suffix (secondary) */}
								<div>
									<div>
										<FormikLabel
											required
											htmlFor="profile.address.address_line_secondary"
											className="block truncate"
											hasError={
												!!form.errors.profile?.address
													?.address_line_secondary &&
												form.touched.profile?.address
													?.address_line_secondary
											}
											data-testid="register.profile.address.address_line_secondary.label"
										>
											{t(
												"register.form.address.form_field.address_line_secondary",
											)}
										</FormikLabel>
										<div>
											<FormikInput
												name="profile.address.address_line_secondary"
												autoComplete="address_line_secondary"
												required
												className="block w-full"
												hasError={
													!!form.errors.profile
														?.address
														?.address_line_secondary &&
													form.touched.profile
														?.address
														?.address_line_secondary
												}
												data-testid="register.profile.address.address_line_secondary"
												aria-label="address_line_secondary"
											/>
											<FormikError
												field="profile.address.address_line_secondary"
												namespace="register"
											/>
										</div>
									</div>
								</div>
								<div>
									<div>
										<FormikLabel
											required
											htmlFor="profile.address.postal_code"
											className="block truncate"
											hasError={
												!!form.errors.profile?.address
													?.postal_code &&
												form.touched.profile?.address
													?.postal_code
											}
											data-testid="register.profile.address.postal_code.label"
										>
											{t(
												"register.form.address.postal_code",
											)}
										</FormikLabel>
										<div>
											<FormikInput
												name="profile.address.postal_code"
												autoComplete="postal_code"
												required
												className="block w-full"
												hasError={
													!!form.errors.profile
														?.address
														?.postal_code &&
													form.touched.profile
														?.address?.postal_code
												}
												data-testid="register.profile.address.postal_code"
												aria-label="postal_code"
											/>
											<FormikError
												field="profile.address.postal_code"
												namespace="register"
											/>
										</div>
									</div>
								</div>
								<div>
									<div>
										<FormikLabel
											required
											htmlFor="profile.address.city"
											className="block truncate"
											hasError={
												!!form.errors.profile?.address
													?.city &&
												form.touched.profile?.address
													?.city
											}
											data-testid="register.profile.address.city.label"
										>
											{t("register.form.address.city")}
										</FormikLabel>
										<div>
											<FormikInput
												name="profile.address.city"
												autoComplete="city"
												required
												className="block w-full"
												hasError={
													!!form.errors.profile
														?.address?.city &&
													form.touched.profile
														?.address?.city
												}
												data-testid="register.profile.address.city"
												aria-label="city"
											/>
											<FormikError
												field="profile.address.city"
												namespace="register"
											/>
										</div>
									</div>
								</div>
							</div>
						)}

						{/** Identity type: Business */}
						{form.values.identity_type ===
							UsersUserProfileIdentityTypeChoices.Business && (
							<div>
								<Heading
									as="h3"
									styleAs="h6"
									className="mr-2 lg:mr-0"
								>
									{t("register.form.business.title")}
								</Heading>
								<p>{t("register.form.business.disclaimer")}</p>
								<div>
									{/** Country */}
									<div className="2xl:col-span-2">
										<FormikLabel
											required
											htmlFor="profile.address.country"
											className="truncate"
										>
											{t("register.form.address.country")}
										</FormikLabel>
										<label className="mt-1">
											<SelectButton className="relative lg:w-1/2">
												{getCountryName(
													form.values.profile?.address
														?.country as CoreLocationCountryChoices,
												)}
												<FiChevronDown className="ml-2" />
												<Select
													onChange={(evt) => {
														evt.preventDefault()
														form.setFieldValue(
															"profile.address.country",
															evt.currentTarget
																.value,
														)
													}}
													name="profile.address.country"
													id="profile.address.country"
													value={
														form.values.profile
															?.address
															?.country ?? ""
													}
												>
													{sortedCountries.map(
														(item) => (
															<option
																value={
																	item.code
																}
																key={item.code}
															>
																{
																	item.localisedCountry
																}
															</option>
														),
													)}
												</Select>
											</SelectButton>
											<FormikError field="profile.address.country" />
										</label>
									</div>
								</div>
								<div>
									{t(
										"register.form.address.country.sublabel",
									)}
								</div>
								<div>
									<div>
										<FormikLabel
											required
											htmlFor="organisation.business_nr"
											className="block truncate"
											hasError={
												!!form.errors.organisation
													?.business_nr &&
												form.touched.organisation
													?.business_nr
											}
											data-testid="register.organisation.business_nr.label"
										>
											{t(
												"register.form.business.form_field.organisation.business_nr",
											)}
										</FormikLabel>
										<div>
											<FormikInput
												name="organisation.business_nr"
												autoComplete="business_nr"
												required
												className="block w-full"
												hasError={
													!!form.errors.organisation
														?.business_nr &&
													form.touched.organisation
														?.business_nr
												}
												data-testid="register.organisation.business_nr"
												aria-label="business_nr"
											/>
											<FormikError
												field="organisation.business_nr"
												namespace="register"
											/>
										</div>
									</div>
								</div>
							</div>
						)}

						<Button
							onClick={() => setCurrentStepNumber(3)}
							className="mt-4"
						>
							{t("register.form_action.next")}
						</Button>
					</div>
				)}

				{/** Step 2: Account */}
				{currentStepNumber === 3 && (
					<div>
						<Heading as="h3" styleAs="h6" className="mr-2 lg:mr-0">
							{t("register.form.account.title")}
						</Heading>
						{/** Email */}
						<div>
							<Label
								required
								htmlFor="email"
								className="block truncate"
								hasError={
									!!form.errors.email && form.touched.email
								}
								data-testid="register.email.label"
							>
								{t("register.form_field.email.label")}
							</Label>
							<div>
								<FormikInput
									name="email"
									type="email"
									autoComplete="email"
									required
									className="block w-full"
									placeholder={t(
										"register.form_field.email.placeholder",
									)}
									hasError={
										!!form.errors.email &&
										form.touched.email
									}
									data-testid="register.email"
									aria-label="email"
								/>
								<FormikError
									field="email"
									namespace="register"
								/>
							</div>
						</div>

						{/** Phone */}
						<div>
							<FormikLabel
								required
								htmlFor="profile.phone"
								className="block truncate"
								hasError={
									!!form.errors.profile?.phone &&
									form.touched.profile?.phone
								}
								data-testid="register.profile.phone.label"
							>
								{t("register.form_field.profile.phone.label")}
							</FormikLabel>
							<div>
								<FormikInput
									name="profile.phone"
									autoComplete="phone"
									required
									className="block w-full"
									hasError={
										!!form.errors.profile?.phone &&
										form.touched.profile?.phone
									}
									data-testid="register.profile.phone"
									aria-label="phone"
								/>
								<FormikError
									field="profile.phone"
									namespace="register"
								/>
							</div>
						</div>

						{/** IBAN */}
						<div>
							<FormikLabel
								required
								htmlFor="iban"
								className="block truncate"
								hasError={
									!!form.errors.iban && form.touched.iban
								}
								data-testid="register.iban.label"
							>
								{t("register.form_field.iban.label")}
							</FormikLabel>
							<div>
								<FormikInput
									name="iban"
									autoComplete="iban"
									required
									className="block w-full"
									placeholder={t(
										"register.form_field.iban.placeholder",
									)}
									hasError={
										!!form.errors.iban && form.touched.iban
									}
									data-testid="register.iban"
									aria-label="iban"
								/>
								<FormikError
									field="iban"
									namespace="register"
								/>
							</div>
						</div>

						{/** Password */}
						<div>
							<FormikLabel
								required
								htmlFor="password"
								className="flex-wrap items-center justify-between truncate"
								data-testid="register.password.label"
								hasError={
									!!form.errors.password &&
									form.touched.password
								}
							>
								{t("register.form_field.password.label")}
							</FormikLabel>
							<div>
								<Input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									className="block w-full"
									onChange={handleChangeWithAnalytics}
									onBlur={form.handleBlur}
									value={form.values.password}
									data-testid="register.password"
									aria-label="password"
									hasError={
										!!form.errors.password &&
										form.touched.password
									}
								/>
								<FormikError
									field="password"
									namespace="register"
								/>
							</div>
						</div>

						{/** Password confirm */}
						<div>
							<FormikLabel
								required
								htmlFor="password"
								className="flex-wrap items-center justify-between truncate"
								data-testid="register.password_confirmation.label"
								hasError={
									!!form.errors.passwordConfirmation &&
									form.touched.passwordConfirmation
								}
							>
								{t(
									"register.form_field.password_confirmation.label",
								)}
							</FormikLabel>
							<div>
								<Input
									id="passwordConfirmation"
									name="passwordConfirmation"
									type="password"
									required
									className="block w-full"
									onChange={handleChangeWithAnalytics}
									onBlur={form.handleBlur}
									value={form.values.passwordConfirmation}
									data-testid="register.password_confirmation"
									aria-label="passwordConfirmation"
									hasError={
										!!form.errors.passwordConfirmation &&
										form.touched.passwordConfirmation
									}
								/>
								<FormikError
									field="passwordConfirmation"
									namespace="register"
								/>
							</div>
						</div>

						{/** Terms and conditions */}
						<div>
							<FormikLabel
								required
								htmlFor="termsAndConditions"
								className="flex text-sm text-gray-900"
								data-testid="register.terms_and_conditions.label"
							>
								<Checkbox
									id="termsAndConditions"
									name="termsAndConditions"
									checked={form.values.termsAndConditions}
									onChange={handleChangeWithAnalytics}
									onBlur={form.handleBlur}
									data-testid="register.terms_and_conditions"
									aria-label="termsAndConditions"
									className="mr-2 mt-1"
								/>
								{t(
									"register.form_field.terms_and_conditions.label",
								)}
							</FormikLabel>
						</div>

						{/** Terms and conditions PSP */}
						<div>
							<FormikLabel
								required
								htmlFor="termsAndConditionsPSP"
								className="flex text-sm text-gray-900"
								data-testid="register.terms_and_conditions_psp.label"
							>
								<Checkbox
									id="termsAndConditionsPSP"
									name="termsAndConditionsPSP"
									checked={form.values.termsAndConditionsPSP}
									onChange={handleChangeWithAnalytics}
									onBlur={form.handleBlur}
									data-testid="register.terms_and_conditions_psp"
									aria-label="termsAndConditionsPSP"
									className="mr-2 mt-1"
								/>
								{t(
									"register.form_field.terms_and_conditions_psp.label",
								)}
							</FormikLabel>
						</div>

						{/** Submit */}
						<div>
							<FormikErrors i18nNamespace="register" />
							<FormikSubmitButton
								data-testid="register.submit"
								className="flex w-full"
								onClick={() =>
									sendEvent("register", "onsubmit")
								}
							>
								{t(
									form.isSubmitting
										? "register.form_status.submitting"
										: "register.form_action.submit",
								)}
							</FormikSubmitButton>
						</div>
					</div>
				)}
			</form>
		</FormikProvider>
	)
}

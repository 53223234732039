// React
import { useEffect, useState } from "react"

// DateTime
import { DateTime, Duration } from "@/lib/dates"

// Utils
import SunCalc from "suncalc"
import { floorTo } from "@/lib/math"

// Constants
import { mapCoordinatesCenterOfNetherlands } from "@/misc/constants"

// UI
import Tippy from "@tippyjs/react"

// Translations
import { Trans, useTrans } from "@/i18n"

// Context
import { useCurrentUserInfo } from "@/context/user"

export function calculate(
	langKey: string,
	diff: Duration,
): { langKey: string; count: number; hours: number; minutes: number } {
	let hours = diff.hours
	let minutes = floorTo(diff.minutes, 15)

	let count = hours

	if (hours === 0) {
		langKey += "_only_minutes"
		count = minutes
	} else if (minutes === 0) {
		langKey += "_only_hours"
		count = hours
	}

	return {
		langKey,
		count,
		hours,
		minutes,
	}
}

export function dateDiff(from: Date, to: Date): Duration {
	return DateTime.fromJSDate(from).diff(DateTime.fromJSDate(to), [
		"hours",
		"minutes",
	])
}

function useCurrentDate(interval = 15 * 60 * 1000): Date {
	const [now, set] = useState(new Date())

	useEffect(() => {
		const work = () => {
			set(new Date())
		}

		const timeout = setInterval(work, interval)
		return () => {
			clearInterval(timeout)
		}
	}, [interval])

	return now
}

/**
 * WelcomeText component
 */
export function WelcomeText() {
	const t = useTrans()
	const now = useCurrentDate()
	const sunData = SunCalc.getTimes(
		now,
		Number(mapCoordinatesCenterOfNetherlands.lat),
		Number(mapCoordinatesCenterOfNetherlands.lng),
	)

	// State
	const [langKey, setLangKey] = useState<string>("")
	const [count, setCount] = useState<number>(0)
	const [hours, setHours] = useState<number>(0)
	const [minutes, setMinutes] = useState<number>(0)

	// Queries
	const { first_name } = useCurrentUserInfo()

	// On mount determine text
	useEffect(() => {
		if (now > sunData.sunset) {
			const tomorrowDate = new Date(now)
			tomorrowDate.setDate(now.getDate() + 1)

			const tomorrowSunData = SunCalc.getTimes(
				tomorrowDate,
				Number(mapCoordinatesCenterOfNetherlands.lat),
				Number(mapCoordinatesCenterOfNetherlands.lng),
			)

			const { langKey, count, hours, minutes } = calculate(
				"common.welcome_message.next_sunrise",
				dateDiff(tomorrowSunData.sunrise, now),
			)

			setLangKey(langKey)
			setCount(count)
			setHours(hours)
			setMinutes(minutes)
		}

		if (now >= sunData.sunrise) {
			const { langKey, count, hours, minutes } = calculate(
				"common.welcome_message.last_sunrise",
				dateDiff(now, sunData.sunrise),
			)

			setLangKey(langKey)
			setCount(count)
			setHours(hours)
			setMinutes(minutes)
		}

		if (now < sunData.sunrise) {
			const { langKey, count, hours, minutes } = calculate(
				"common.welcome_message.next_sunrise",
				dateDiff(sunData.sunrise, now),
			)

			setLangKey(langKey)
			setCount(count)
			setHours(hours)
			setMinutes(minutes)
		}
	}, [])

	return (
		<div className="text-md hidden h-full items-center overflow-hidden text-gray-400 md:flex">
			<Tippy
				content={t("common.layout.welcome.tippy")}
				className="text-center"
			>
				<p className="truncate">
					<Trans
						i18nKey="common.layout.welcome"
						ns="common"
						values={{ name: first_name }}
						components={{
							strong: <span className="pr-1 text-primary-500" />,
							message: (
								<>
									{t(langKey, {
										hours: Math.floor(hours),
										minutes: Math.floor(minutes),
										count,
									})}
								</>
							),
						}}
					/>
				</p>
			</Tippy>
		</div>
	)
}

import { useContext } from "react"

// Navigation
import { matchPath, useLocation } from "react-router-dom"
import { Pages } from "@/misc/pages"
import { Link } from "@/components/Anchor"

// Translation
import { useTrans } from "@/i18n"

// UI
import { classNames } from "@/lib/classnames"

// Context
import { MainLayoutContext } from "@/layouts/MainLayout"
import { useInvestmentTests } from "@/context/investmentTests"
import {
	useCurrentUserMessagesCount,
	useCurrentUserPayoutsCount,
	useCurrentUserProvisonalInvestmentsCount,
	useCurrentUserInvestmentsCount,
	useCurrentUserInvestmentsInProductionCount,
} from "@/context/user"

/**
 * InvestmentsSubMenu
 * @returns
 */
export function InvestmentsSubMenu() {
	// Router
	const location = useLocation()

	// Context
	const { setMobileMenuOpen } = useContext(MainLayoutContext)
	const { amountOfRequiredActionsLeft } = useInvestmentTests()
	const messagesCount = useCurrentUserMessagesCount()
	const payoutsCount = useCurrentUserPayoutsCount()
	const provisionalInvestmentsCount =
		useCurrentUserProvisonalInvestmentsCount()
	const investmentsCount = useCurrentUserInvestmentsCount()
	const investmentsInProductionCount =
		useCurrentUserInvestmentsInProductionCount()

	/**
	 * The Pages.InvestmentsProjectsDashboard page is a special case because it has nested routes in the url
	 * that need to be rendered on the same level and not both be marked as active
	 *
	 * @returns
	 */
	function isProjectSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Pages.InvestmentsProjectsDashboard) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investments/projects/:id/:tab",
				end: true,
			},
			location.pathname,
		)

		// Now exclude the children pages from being marked as active
		const pagesToCheck = [
			Pages.ProvisionalInvestments,
			Pages.InvestmentsProjectsFiscalOverview,
			Pages.InvestmentsProjectsProduction,
			Pages.Communications,
			Pages.InvestmentsProjectsPayments,
		]
		return (
			matchesPath !== null &&
			!pagesToCheck.some((page) => location.pathname.includes(page))
		)
	}

	/**
	 * Test if location path is any child page of the investor profile
	 * @returns
	 */
	function isInvestorProfileSpecificSubPath() {
		// Return true if it is a full match
		if (location.pathname === Pages.InvestorProfile) {
			return true
		}

		// Check if the current path is a subpath of the ProjectsDashboard page
		const matchesPath = matchPath(
			{
				path: "/investor/:tab",
				end: true,
			},
			location.pathname,
		)
		return matchesPath !== null
	}

	const t = useTrans("common")

	function closeMenu() {
		setMobileMenuOpen(false)
	}

	return (
		<nav className="space-y-1 pr-3">
			{investmentsCount > 0 ? (
				<Link
					className={classNames(
						isProjectSpecificSubPath() && "active-navigation-item",
						"block truncate text-sm hover:text-gray-500",
					)}
					href={Pages.InvestmentsProjectsDashboard}
					onClick={closeMenu}
				>
					{t("common:common.navigation.investments.projects.link")}
				</Link>
			) : null}

			{provisionalInvestmentsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Pages.ProvisionalInvestments}
					onClick={closeMenu}
				>
					{t("common:common.nav.provisional_investments")}
				</Link>
			) : null}

			{investmentsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Pages.InvestmentsProjectsFiscalOverview}
					onClick={closeMenu}
				>
					{t("common:common.nav.fiscal")}
				</Link>
			) : null}

			{/** Show production when atleast 1 project has Production data */}
			{investmentsInProductionCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Pages.InvestmentsProjectsProduction}
					onClick={closeMenu}
				>
					{t("common:common.nav.total_production")}
				</Link>
			) : null}

			{/** Payouts */}
			{payoutsCount > 0 ? (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Pages.InvestmentsProjectsPayments}
					onClick={closeMenu}
				>
					{t("common:common.nav.payouts")}
				</Link>
			) : null}

			{/** Communication hub */}
			{messagesCount > 0 && (
				<Link
					className={({ isActive }) =>
						classNames(
							isActive && "active-navigation-item",
							"block truncate text-sm hover:text-gray-500",
						)
					}
					href={Pages.Communications}
					onClick={closeMenu}
				>
					{t("common:common.nav.all_messages")}
				</Link>
			)}

			{/** Investor profile */}
			<Link
				className="flex w-full items-center focus:no-underline"
				href={Pages.InvestorProfile}
				onClick={closeMenu}
			>
				<>
					<span
						className={classNames(
							isInvestorProfileSpecificSubPath() &&
								"active-navigation-item",
							"truncate text-sm hover:text-gray-500",
						)}
					>
						{t("common:common.navigation.investor-profile.title")}
					</span>

					{/** Show badge with number */}
					{amountOfRequiredActionsLeft !== undefined &&
						amountOfRequiredActionsLeft !== 0 && (
							<span className="ml-1 block h-4 w-4 items-center rounded-full bg-red-600 text-center text-xs text-white">
								{" "}
								{amountOfRequiredActionsLeft}
							</span>
						)}
				</>
			</Link>
		</nav>
	)
}

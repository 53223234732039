import { Link } from "react-router-dom"
import { Card } from "@/components/Card"
import { Trans, useTrans } from "@/i18n"
import { PageTemplate } from "@/templates/PageTemplate"

export const NotFound = () => {
	const t = useTrans("not-found")

	return (
		<PageTemplate title={t("not-found.heading")}>
			<Card>
				<p
					className="text-sm text-gray-500"
					data-testid="not-found.copy"
				>
					<Trans
						ns="not-found"
						i18nKey="not-found.copy"
						components={{
							a: (
								<Link
									to="/"
									className="font-medium text-secondary-300 hover:text-secondary-700"
								/>
							),
						}}
					/>
				</p>
			</Card>
		</PageTemplate>
	)
}

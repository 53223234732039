import { Heading } from "@/components/Typography"

export function GraphHeader({
	title,
	subtitle,
}: {
	title: string
	subtitle?: string
}) {
	return (
		<>
			<Heading className="truncate text-dark" as="h2" styleAs="h6">
				{title}
			</Heading>
			{subtitle ? (
				<p className="truncate text-xs text-gray-500">{subtitle}</p>
			) : null}
		</>
	)
}

export function GraphHeaderPreloader({ className }: { className?: string }) {
	return (
		<div className={className}>
			<Heading
				className="dummy-text w-full animate-pulse bg-gray-50 text-dark"
				as="h2"
				styleAs="h6"
			/>
			<p className="dummy-text w-[50%] animate-pulse bg-gray-50 text-xs text-gray-500" />
		</div>
	)
}

import { Suspense } from "react"

// SEO
import { Helmet } from "@/lib/seo"

// Router
import { Route, Routes } from "@/lib/router"
import { useTrans } from "@/i18n"
import { PageTemplate, PageTemplateLink } from "@/templates/PageTemplate"

// Pages
import { Pages } from "@/misc/pages"
import { Investments as InvestmentsView } from "./components/Investments"
import { ProvisionalInvestments } from "./Investments"
import { Fiscal } from "./Fiscal"
import { Production } from "./Production"
import { Message } from "../project/pages/Message"
import { AllCommunications } from "./AllCommunications"
import { AllPayments } from "./Payments"

// UI
import { LoadingScreen } from "@/screens/LoadingScreens"
import { InvestmentOppertunityBanner } from "@/components/banners/InvestmentOppertunityBanner"
import { Card } from "@/components/Card"
import { Heading } from "@/components/Typography"

// Context
import {
	useCurrentUserMessagesCount,
	useCurrentUserPayoutsCount,
	useCurrentUserProvisonalInvestmentsCount,
	useCurrentUserInvestmentsCount,
	useCurrentUserInvestmentsInProductionCount,
} from "@/context/user"

/**
 * InvestmentsPage
 * @returns
 */
export function InvestmentsPage() {
	const t = useTrans(["common", "investments"])

	// Context
	const messagesCount = useCurrentUserMessagesCount()
	const payoutsCount = useCurrentUserPayoutsCount()
	const provisionalInvestmentsCount =
		useCurrentUserProvisonalInvestmentsCount()
	const investmentsCount = useCurrentUserInvestmentsCount()
	const investmentsInProductionCount =
		useCurrentUserInvestmentsInProductionCount()

	return (
		<>
			<Helmet>
				<title>{t("investments:investments.title")}</title>
			</Helmet>
			<PageTemplate
				title={t("investments:investments.title")}
				tabs={
					<>
						<PageTemplateLink
							to={Pages.InvestmentsProjectsDashboard}
							data-testid="investments.header"
							name={t("investments:investments.header")}
							analyticsId="investments"
						/>

						{provisionalInvestmentsCount > 0 ? (
							<PageTemplateLink
								to={Pages.ProvisionalInvestments}
								data-testid="investments.provisional_investments"
								name={t(
									"common:common.nav.provisional_investments",
								)}
								analyticsId="common.nav.provisional_investments"
							/>
						) : null}

						{investmentsCount > 0 ? (
							<PageTemplateLink
								to={Pages.InvestmentsProjectsFiscalOverview}
								data-testid="investments.fiscal.header"
								name={t("common:common.nav.fiscal")}
								analyticsId="investments.fiscal"
							/>
						) : null}

						{/** Show production when atleast 1 project has Production data */}
						{investmentsInProductionCount > 0 ? (
							<PageTemplateLink
								to={Pages.InvestmentsProjectsProduction}
								data-testid="investments.production.header"
								name={t("common:common.nav.total_production")}
								analyticsId="investments.production"
							/>
						) : null}

						{payoutsCount > 0 ? (
							<PageTemplateLink
								to={Pages.InvestmentsProjectsPayments}
								data-testid="investments.payouts"
								name={t("common:common.nav.payouts")}
								analyticsId="investments.payouts"
							/>
						) : null}

						{messagesCount > 0 ? (
							<PageTemplateLink
								to={Pages.Communications}
								data-testid="investments.all_messages"
								name={t("common:common.nav.all_messages")}
								analyticsId="investments.all_messages"
							/>
						) : null}
					</>
				}
			>
				<Suspense fallback={<LoadingScreen className="h-full" />}>
					<Routes>
						<Route
							path="provisional"
							element={<ProvisionalInvestments />}
						/>
						<Route path="fiscal-overview" element={<Fiscal />} />
						<Route path="production" element={<Production />} />
						<Route
							path="messages"
							element={<AllCommunications />}
						/>
						<Route path="messages/:id" element={<Message />} />
						<Route path="payments" element={<AllPayments />} />
						<Route path="/" element={<Index />} />
					</Routes>
				</Suspense>
			</PageTemplate>
		</>
	)
}

function Index() {
	const t = useTrans("investments")

	return (
		<>
			<InvestmentOppertunityBanner />
			<Card className="mb-8 mt-5">
				<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
					{t("investments.header")}
				</Heading>
				<p className="text-sm text-gray-500 sm:w-3/4 lg:w-1/2">
					{t("investments.copy")}
				</p>
			</Card>
			<Card variant="gray">
				<InvestmentsView />
			</Card>
		</>
	)
}

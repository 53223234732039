import { ReactNode, useLayoutEffect } from "react"
import {
	NavLink,
	NavLinkProps,
	useMatch,
	useResolvedPath,
} from "react-router-dom"
import { useTrans } from "@/i18n"
import { sendEvent } from "@/lib/analytics"

import { Pages } from "@/misc/pages"

// Icons
import { BackIcon } from "@/components/BackIcon"
import { CheckIcon } from "@heroicons/react/outline"

// UI
import { classNames } from "@/lib/classnames"
import { Heading } from "@/components/Typography"

type PageTemplateProps = {
	tabs?: ReactNode
	title?: string
	topRightComponent?: ReactNode
	backHref?: Pages
	children?: ReactNode
}

export function PageTemplate({
	tabs,
	title,
	topRightComponent,
	backHref,
	children,
}: PageTemplateProps) {
	const t = useTrans()

	useLayoutEffect(() => {
		document.querySelector("html")?.classList.add("h-full", "bg-white")
		document.body.classList.add("h-full")
		return () => {
			document
				.querySelector("html")
				?.classList.remove("h-full", "bg-white")
			document.body.classList.remove("h-full")
		}
	}, [])

	return (
		<div
			className={classNames(
				"flex w-full flex-grow flex-col",
				"px-4 py-6 pt-6 sm:px-7 sm:pt-8",
			)}
		>
			{/** Back arrow */}
			{backHref && (
				<div className="mb-4">
					<BackIcon href={backHref} />
				</div>
			)}

			{/** Page title */}
			{title && (
				<div className="flex max-w-7xl items-start">
					<div className="flex-1">
						<Heading as="h1" styleAs="h5">
							{title}
						</Heading>
					</div>

					{/** Top right component next to page title */}
					{topRightComponent && <>{topRightComponent}</>}
				</div>
			)}

			{/** Navigation tabs */}
			{tabs && (
				<div className={classNames(title ? "mt-2" : null, "md:mt-0")}>
					{/* separate nav so it can scroll. minus margin to compensate for border */}
					<nav
						className="-mx-4 grid grid-cols-2 sm:grid-cols-3 md:flex md:flex-wrap"
						aria-label={t("common.layout.page_navigation_title")}
					>
						{tabs}
					</nav>
				</div>
			)}

			{/** If we have children */}
			{children && (
				<main
					className={classNames(
						title || tabs ? "mt-6" : null,
						"h-full max-w-7xl",
					)}
				>
					{children}
				</main>
			)}
		</div>
	)
}

type PageTemplateLinkProps = Omit<NavLinkProps, "to"> & {
	to: string
	match?: string
	name: string
	analyticsId?: string
	badgeNumber?: number
	badgeVerified?: boolean
}

export function PageTemplateLink({
	to,
	match,
	name,
	badgeNumber,
	badgeVerified,
	analyticsId,
	end = true,
	...rest
}: PageTemplateLinkProps) {
	let resolved = useResolvedPath(to)
	let current = useMatch({ path: resolved.pathname, end })

	return (
		<NavLink
			{...rest}
			to={to}
			key={name}
			className={classNames(
				current ? "text-black" : "text-gray-500 hover:text-gray-700",
				"border-b border-gray-300",
				"group px-4",
				"whitespace-nowrap text-sm font-medium",
			)}
			aria-current={current ? "page" : undefined}
			onClick={(e) => {
				rest.onClick?.(e)
				if (analyticsId) {
					sendEvent("pagetemplatelink", `${analyticsId}_onclick`)
				}
			}}
		>
			<span
				className={classNames(
					"flex h-full translate-y-px transform gap-1 truncate border-b-2 py-3 lg:py-4",
					current
						? "border-primary-500 group-hover:border-primary-500"
						: "border-transparent group-hover:border-b-2 group-hover:border-gray-300",
				)}
			>
				{name}

				{/** Show badge with number */}
				{badgeNumber !== undefined && badgeNumber !== 0 && (
					<span className="block h-4 w-4 items-center rounded-full bg-red-600 text-center text-xs text-white">
						{" "}
						{badgeNumber}
					</span>
				)}

				{/** Badge with checkmark */}
				{badgeVerified === true && (
					<CheckIcon
						aria-hidden="true"
						className="h-5 w-5 text-lg text-green-500"
					/>
				)}
			</span>
		</NavLink>
	)
}

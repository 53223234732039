import debounce from "lodash.debounce"

// Env variables
const GTM_CONTAINER_ID = process.env.REACT_APP_GTM_CONTAINER_ID

export const generateSendEventDebounced = (limit = 1000) => {
	return debounce(sendEvent, limit)
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const sendEvent = (category: string, action: string, args?: any) => {
	if (typeof window.gtag !== "undefined") {
		window.gtag("event", category, {
			action,
			...args,
		})
	}
}

// These types are specifically for privacy-related settings and do not exist on @types/gtag
type GtagSetOptions = {
	allow_google_signals: boolean
	allow_ad_personalization_signals: boolean
	restricted_data_processing: boolean
	send_page_view: boolean
}

/**
 * Disable GTM / GA
 * DOCS: https://developers.google.com/tag-platform/security/guides/privacy#gtag.js_1
 */
export const enableGoogleTagManager = (enabled: boolean) => {
	if (enabled === false) {
		// Should stop GA from all tracking
		;(window as any)[`ga-disable-${GTM_CONTAINER_ID}`] = true

		// Disabled specific tracking features
		gtag("set", { allow_google_signals: false } as GtagSetOptions)
		gtag("set", {
			allow_ad_personalization_signals: false,
		} as GtagSetOptions)
		gtag("set", { restricted_data_processing: true } as GtagSetOptions)
		gtag("set", { send_page_view: false } as GtagSetOptions)
	}

	// Re-enable
	if (enabled === true) {
		;(window as any)[`ga-disable-${GTM_CONTAINER_ID}`] = false
		gtag("set", { allow_google_signals: true } as GtagSetOptions)
		gtag("set", {
			allow_ad_personalization_signals: true,
		} as GtagSetOptions)
		gtag("set", { restricted_data_processing: false } as GtagSetOptions)
		gtag("set", { send_page_view: true } as GtagSetOptions)
	}
}

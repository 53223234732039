// React
import { Suspense } from "react"

// Router
import { useNavigate, useParams } from "@/lib/router"
import { Pages } from "@/misc/routes"

// Queries
import { useSingleProjectStatsQuery } from "@/api/graphql"

// UI
import { Card } from "@/components/Card"
import { InternalStateOfReturnGraph } from "./_components/InternalStateOfReturnGraph"
import {
	ProjectSolarInterestPeriodsTable,
	ProjectSolarInterestPeriodsTableLoading,
} from "./_components/ProjectSolarInterestPeriodsTable"
import { PageTemplate } from "@/templates/PageTemplate"
import { ProjectSelector } from "@/components/ProjectSelector"
import {
	SingleProjectStatsInternalRateOfReturn,
	SingleProjectStatsInternalRateOfReturnLoading,
} from "./_components/SingleProjectStatsInternalRateOfReturn"

// I18n
import { useTrans } from "@/i18n"

/**
 * SingleProjectStats
 * @param param0
 * @returns
 */
export function SingleProjectStats() {
	// I18n
	const t = useTrans()

	// Router
	const navigate = useNavigate()
	const params = useParams()
	const projectId = Number(params.id)

	// Fetch
	const { data } = useSingleProjectStatsQuery({
		id: String(params.id),
	})

	return (
		<div className="container my-5">
			<PageTemplate
				title={data?.project?.name || ""}
				topRightComponent={
					<ProjectSelector
						projectId={projectId}
						onChange={(id) =>
							navigate(
								`${Pages.StatisticsPlatformSingleProject}/${id}`,
							)
						}
					/>
				}
				backHref={Pages.StatisticsPlatform}
			>
				<div className="grid grid-cols-1 gap-8 md:col-span-4 lg:grid-cols-2 xl:col-span-3">
					<Suspense
						fallback={
							<SingleProjectStatsInternalRateOfReturnLoading />
						}
					>
						<SingleProjectStatsInternalRateOfReturn
							projectId={projectId}
						/>
					</Suspense>

					<Card className="h-full group-hover:bg-gray-50 md:col-span-1 xl:col-span-1">
						<div>
							Project type:{" "}
							<strong>
								{t(
									`common:common.project.type.${data?.project?.type}`,
								)}
							</strong>
						</div>
						<div>
							Startdatum: <strong>{data?.project?.start}</strong>
						</div>
						<div>
							Energie leverancier:{" "}
							<strong>{data?.project?.supplier?.name}</strong>
						</div>
						<div>
							Financiële structuur:{" "}
							<b>{data?.project?.amortization_schedule}</b>
							<br />
							{data?.project?.cap_eur_interest_per_share &&
								data?.project?.floor_eur_interest_per_share && (
									<>
										CAP / FLOOR:{" "}
										<b>
											&euro;{" "}
											{
												data?.project
													?.cap_eur_interest_per_share
											}
											/ &euro;{" "}
											{
												data?.project
													?.floor_eur_interest_per_share
											}
										</b>
										<br />
									</>
								)}
						</div>
					</Card>
				</div>

				<Suspense>
					<InternalStateOfReturnGraph />
				</Suspense>

				<Suspense
					fallback={<ProjectSolarInterestPeriodsTableLoading />}
				>
					<ProjectSolarInterestPeriodsTable />
				</Suspense>
			</PageTemplate>
		</div>
	)
}

/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 * ZonHub
 * Project management for Solar
 * OpenAPI spec version: 1.0.0
 */
import type {
	MutationFunction,
	QueryFunction,
	QueryKey,
	UseMutationOptions,
	UseMutationResult,
	UseQueryOptions,
	UseQueryResult,
} from "@tanstack/react-query"
import { useMutation, useQuery } from "@tanstack/react-query"
import { api, apiWithTokenAuth } from "../../api"
import type {
	Activation,
	ApiSchemaRetrieve200Four,
	ApiSchemaRetrieve200One,
	ApiSchemaRetrieve200Three,
	ApiSchemaRetrieve200Two,
	ApiSchemaRetrieveParams,
	ApiV1PaymentOwnerVerificationRetrieveParams,
	ApiV1PaymentSupplierVerificationRetrieveParams,
	BackupCodeResponse,
	ChangePrimaryMethod,
	CodeLogin,
	CodeResponse,
	ConfigResponse,
	EndexPrice,
	EndexProgress,
	EndexTariff,
	LivePerformance,
	Login,
	LoginCodeResponse,
	LoginResponse,
	MFAMethodCode,
	MFAMethodDeactivationValidator,
	OwnerVerification,
	OwnerVerificationResponse,
	PasswordResetConfirmRetype,
	PatchedUser,
	ProductionFactor,
	ProjectDetail,
	ProjectList,
	RequestMFAMethodActivationConfirm,
	RequestMFAMethodBackupCodesRegeneration,
	SendEmailReset,
	SetPasswordRetype,
	SetUsername,
	SupplierVerification,
	SupplierVerificationResponse,
	User,
	UserCreatePasswordRetype,
	UserMFAMethod,
	UsernameResetConfirm,
	UserProfile,
	VerificationPayment,
} from ".././@types"

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
	T,
>() => T extends Y ? 1 : 2
	? A
	: B

type WritableKeys<T> = {
	[P in keyof T]-?: IfEquals<
		{ [Q in P]: T[P] },
		{ -readonly [Q in P]: T[P] },
		P
	>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
	k: infer I,
) => void
	? I
	: never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
	? {
			[P in keyof Writable<T>]: T[P] extends object
				? NonReadonly<NonNullable<T[P]>>
				: T[P]
	  }
	: DistributeReadOnlyOverUnions<T>

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

export const apiAuthActivateCreate = (method: string) => {
	return apiWithTokenAuth<void>({
		url: `/api/auth/${method}/activate/`,
		method: "POST",
	})
}

export const getApiAuthActivateCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthActivateCreate>>,
		TError,
		{ method: string },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthActivateCreate>>,
	TError,
	{ method: string },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthActivateCreate>>,
		{ method: string }
	> = (props) => {
		const { method } = props ?? {}

		return apiAuthActivateCreate(method)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthActivateCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthActivateCreate>>
>

export type ApiAuthActivateCreateMutationError = unknown

export const useApiAuthActivateCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthActivateCreate>>,
		TError,
		{ method: string },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthActivateCreate>>,
	TError,
	{ method: string },
	TContext
> => {
	const mutationOptions = getApiAuthActivateCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthActivateConfirmCreate = (
	method: string,
	requestMFAMethodActivationConfirm: RequestMFAMethodActivationConfirm,
) => {
	return apiWithTokenAuth<BackupCodeResponse>({
		url: `/api/auth/${method}/activate/confirm/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: requestMFAMethodActivationConfirm,
	})
}

export const getApiAuthActivateConfirmCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthActivateConfirmCreate>>,
		TError,
		{ method: string; data: RequestMFAMethodActivationConfirm },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthActivateConfirmCreate>>,
	TError,
	{ method: string; data: RequestMFAMethodActivationConfirm },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthActivateConfirmCreate>>,
		{ method: string; data: RequestMFAMethodActivationConfirm }
	> = (props) => {
		const { method, data } = props ?? {}

		return apiAuthActivateConfirmCreate(method, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthActivateConfirmCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthActivateConfirmCreate>>
>
export type ApiAuthActivateConfirmCreateMutationBody =
	RequestMFAMethodActivationConfirm
export type ApiAuthActivateConfirmCreateMutationError = unknown

export const useApiAuthActivateConfirmCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthActivateConfirmCreate>>,
		TError,
		{ method: string; data: RequestMFAMethodActivationConfirm },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthActivateConfirmCreate>>,
	TError,
	{ method: string; data: RequestMFAMethodActivationConfirm },
	TContext
> => {
	const mutationOptions =
		getApiAuthActivateConfirmCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthCodesRegenerateCreate = (
	method: string,
	requestMFAMethodBackupCodesRegeneration: RequestMFAMethodBackupCodesRegeneration,
) => {
	return apiWithTokenAuth<BackupCodeResponse>({
		url: `/api/auth/${method}/codes/regenerate/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: requestMFAMethodBackupCodesRegeneration,
	})
}

export const getApiAuthCodesRegenerateCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthCodesRegenerateCreate>>,
		TError,
		{ method: string; data: RequestMFAMethodBackupCodesRegeneration },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthCodesRegenerateCreate>>,
	TError,
	{ method: string; data: RequestMFAMethodBackupCodesRegeneration },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthCodesRegenerateCreate>>,
		{ method: string; data: RequestMFAMethodBackupCodesRegeneration }
	> = (props) => {
		const { method, data } = props ?? {}

		return apiAuthCodesRegenerateCreate(method, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthCodesRegenerateCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthCodesRegenerateCreate>>
>
export type ApiAuthCodesRegenerateCreateMutationBody =
	RequestMFAMethodBackupCodesRegeneration
export type ApiAuthCodesRegenerateCreateMutationError = unknown

export const useApiAuthCodesRegenerateCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthCodesRegenerateCreate>>,
		TError,
		{ method: string; data: RequestMFAMethodBackupCodesRegeneration },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthCodesRegenerateCreate>>,
	TError,
	{ method: string; data: RequestMFAMethodBackupCodesRegeneration },
	TContext
> => {
	const mutationOptions =
		getApiAuthCodesRegenerateCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthDeactivateCreate = (
	method: string,
	mFAMethodDeactivationValidator: MFAMethodDeactivationValidator,
) => {
	return apiWithTokenAuth<void>({
		url: `/api/auth/${method}/deactivate/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: mFAMethodDeactivationValidator,
	})
}

export const getApiAuthDeactivateCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthDeactivateCreate>>,
		TError,
		{ method: string; data: MFAMethodDeactivationValidator },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthDeactivateCreate>>,
	TError,
	{ method: string; data: MFAMethodDeactivationValidator },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthDeactivateCreate>>,
		{ method: string; data: MFAMethodDeactivationValidator }
	> = (props) => {
		const { method, data } = props ?? {}

		return apiAuthDeactivateCreate(method, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthDeactivateCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthDeactivateCreate>>
>
export type ApiAuthDeactivateCreateMutationBody = MFAMethodDeactivationValidator
export type ApiAuthDeactivateCreateMutationError = unknown

export const useApiAuthDeactivateCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthDeactivateCreate>>,
		TError,
		{ method: string; data: MFAMethodDeactivationValidator },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthDeactivateCreate>>,
	TError,
	{ method: string; data: MFAMethodDeactivationValidator },
	TContext
> => {
	const mutationOptions = getApiAuthDeactivateCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthCodeRequestCreate = (mFAMethodCode: MFAMethodCode) => {
	return apiWithTokenAuth<CodeResponse>({
		url: `/api/auth/code/request/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: mFAMethodCode,
	})
}

export const getApiAuthCodeRequestCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthCodeRequestCreate>>,
		TError,
		{ data: MFAMethodCode },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthCodeRequestCreate>>,
	TError,
	{ data: MFAMethodCode },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthCodeRequestCreate>>,
		{ data: MFAMethodCode }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthCodeRequestCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthCodeRequestCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthCodeRequestCreate>>
>
export type ApiAuthCodeRequestCreateMutationBody = MFAMethodCode
export type ApiAuthCodeRequestCreateMutationError = unknown

export const useApiAuthCodeRequestCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthCodeRequestCreate>>,
		TError,
		{ data: MFAMethodCode },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthCodeRequestCreate>>,
	TError,
	{ data: MFAMethodCode },
	TContext
> => {
	const mutationOptions = getApiAuthCodeRequestCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthLoginCreate = (login: Login) => {
	return api<LoginResponse>({
		url: `/api/auth/login/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: login,
	})
}

export const getApiAuthLoginCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthLoginCreate>>,
		TError,
		{ data: Login },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthLoginCreate>>,
	TError,
	{ data: Login },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthLoginCreate>>,
		{ data: Login }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthLoginCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthLoginCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthLoginCreate>>
>
export type ApiAuthLoginCreateMutationBody = Login
export type ApiAuthLoginCreateMutationError = unknown

export const useApiAuthLoginCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthLoginCreate>>,
		TError,
		{ data: Login },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthLoginCreate>>,
	TError,
	{ data: Login },
	TContext
> => {
	const mutationOptions = getApiAuthLoginCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthLoginCodeCreate = (codeLogin: CodeLogin) => {
	return api<LoginCodeResponse>({
		url: `/api/auth/login/code/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: codeLogin,
	})
}

export const getApiAuthLoginCodeCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthLoginCodeCreate>>,
		TError,
		{ data: CodeLogin },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthLoginCodeCreate>>,
	TError,
	{ data: CodeLogin },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthLoginCodeCreate>>,
		{ data: CodeLogin }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthLoginCodeCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthLoginCodeCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthLoginCodeCreate>>
>
export type ApiAuthLoginCodeCreateMutationBody = CodeLogin
export type ApiAuthLoginCodeCreateMutationError = unknown

export const useApiAuthLoginCodeCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthLoginCodeCreate>>,
		TError,
		{ data: CodeLogin },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthLoginCodeCreate>>,
	TError,
	{ data: CodeLogin },
	TContext
> => {
	const mutationOptions = getApiAuthLoginCodeCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthLogoutCreate = () => {
	return apiWithTokenAuth<void>({ url: `/api/auth/logout/`, method: "POST" })
}

export const getApiAuthLogoutCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthLogoutCreate>>,
		TError,
		void,
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthLogoutCreate>>,
	TError,
	void,
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthLogoutCreate>>,
		void
	> = () => {
		return apiAuthLogoutCreate()
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthLogoutCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthLogoutCreate>>
>

export type ApiAuthLogoutCreateMutationError = unknown

export const useApiAuthLogoutCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthLogoutCreate>>,
		TError,
		void,
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthLogoutCreate>>,
	TError,
	void,
	TContext
> => {
	const mutationOptions = getApiAuthLogoutCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthMfaChangePrimaryMethodCreate = (
	changePrimaryMethod: ChangePrimaryMethod,
) => {
	return apiWithTokenAuth<void>({
		url: `/api/auth/mfa/change-primary-method/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: changePrimaryMethod,
	})
}

export const getApiAuthMfaChangePrimaryMethodCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthMfaChangePrimaryMethodCreate>>,
		TError,
		{ data: ChangePrimaryMethod },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthMfaChangePrimaryMethodCreate>>,
	TError,
	{ data: ChangePrimaryMethod },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthMfaChangePrimaryMethodCreate>>,
		{ data: ChangePrimaryMethod }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthMfaChangePrimaryMethodCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthMfaChangePrimaryMethodCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthMfaChangePrimaryMethodCreate>>
>
export type ApiAuthMfaChangePrimaryMethodCreateMutationBody =
	ChangePrimaryMethod
export type ApiAuthMfaChangePrimaryMethodCreateMutationError = unknown

export const useApiAuthMfaChangePrimaryMethodCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthMfaChangePrimaryMethodCreate>>,
		TError,
		{ data: ChangePrimaryMethod },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthMfaChangePrimaryMethodCreate>>,
	TError,
	{ data: ChangePrimaryMethod },
	TContext
> => {
	const mutationOptions =
		getApiAuthMfaChangePrimaryMethodCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthMfaConfigRetrieve = (signal?: AbortSignal) => {
	return apiWithTokenAuth<ConfigResponse>({
		url: `/api/auth/mfa/config/`,
		method: "GET",
		signal,
	})
}

export const getApiAuthMfaConfigRetrieveQueryKey = () => {
	return [`/api/auth/mfa/config/`] as const
}

export const getApiAuthMfaConfigRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiAuthMfaConfigRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthMfaConfigRetrieve>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getApiAuthMfaConfigRetrieveQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiAuthMfaConfigRetrieve>>
	> = ({ signal }) => apiAuthMfaConfigRetrieve(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthMfaConfigRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiAuthMfaConfigRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthMfaConfigRetrieve>>
>
export type ApiAuthMfaConfigRetrieveQueryError = unknown

export const useApiAuthMfaConfigRetrieve = <
	TData = Awaited<ReturnType<typeof apiAuthMfaConfigRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthMfaConfigRetrieve>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiAuthMfaConfigRetrieveQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

export const apiAuthMfaUserActiveMethodsList = (signal?: AbortSignal) => {
	return apiWithTokenAuth<UserMFAMethod[]>({
		url: `/api/auth/mfa/user-active-methods/`,
		method: "GET",
		signal,
	})
}

export const getApiAuthMfaUserActiveMethodsListQueryKey = () => {
	return [`/api/auth/mfa/user-active-methods/`] as const
}

export const getApiAuthMfaUserActiveMethodsListQueryOptions = <
	TData = Awaited<ReturnType<typeof apiAuthMfaUserActiveMethodsList>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthMfaUserActiveMethodsList>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getApiAuthMfaUserActiveMethodsListQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiAuthMfaUserActiveMethodsList>>
	> = ({ signal }) => apiAuthMfaUserActiveMethodsList(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthMfaUserActiveMethodsList>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiAuthMfaUserActiveMethodsListQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthMfaUserActiveMethodsList>>
>
export type ApiAuthMfaUserActiveMethodsListQueryError = unknown

export const useApiAuthMfaUserActiveMethodsList = <
	TData = Awaited<ReturnType<typeof apiAuthMfaUserActiveMethodsList>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthMfaUserActiveMethodsList>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiAuthMfaUserActiveMethodsListQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

export const apiAuthUsersList = (signal?: AbortSignal) => {
	return apiWithTokenAuth<User[]>({
		url: `/api/auth/users/`,
		method: "GET",
		signal,
	})
}

export const getApiAuthUsersListQueryKey = () => {
	return [`/api/auth/users/`] as const
}

export const getApiAuthUsersListQueryOptions = <
	TData = Awaited<ReturnType<typeof apiAuthUsersList>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthUsersList>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey = queryOptions?.queryKey ?? getApiAuthUsersListQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiAuthUsersList>>
	> = ({ signal }) => apiAuthUsersList(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthUsersList>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiAuthUsersListQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersList>>
>
export type ApiAuthUsersListQueryError = unknown

export const useApiAuthUsersList = <
	TData = Awaited<ReturnType<typeof apiAuthUsersList>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthUsersList>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiAuthUsersListQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

export const apiAuthUsersCreate = (
	userCreatePasswordRetype: NonReadonly<UserCreatePasswordRetype>,
) => {
	return apiWithTokenAuth<UserCreatePasswordRetype>({
		url: `/api/auth/users/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: userCreatePasswordRetype,
	})
}

export const getApiAuthUsersCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersCreate>>,
		TError,
		{ data: NonReadonly<UserCreatePasswordRetype> },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersCreate>>,
	TError,
	{ data: NonReadonly<UserCreatePasswordRetype> },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersCreate>>,
		{ data: NonReadonly<UserCreatePasswordRetype> }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersCreate>>
>
export type ApiAuthUsersCreateMutationBody =
	NonReadonly<UserCreatePasswordRetype>
export type ApiAuthUsersCreateMutationError = unknown

export const useApiAuthUsersCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersCreate>>,
		TError,
		{ data: NonReadonly<UserCreatePasswordRetype> },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersCreate>>,
	TError,
	{ data: NonReadonly<UserCreatePasswordRetype> },
	TContext
> => {
	const mutationOptions = getApiAuthUsersCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersRetrieve = (id: number, signal?: AbortSignal) => {
	return apiWithTokenAuth<User>({
		url: `/api/auth/users/${id}/`,
		method: "GET",
		signal,
	})
}

export const getApiAuthUsersRetrieveQueryKey = (id: number) => {
	return [`/api/auth/users/${id}/`] as const
}

export const getApiAuthUsersRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiAuthUsersRetrieve>>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiAuthUsersRetrieve>>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getApiAuthUsersRetrieveQueryKey(id)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiAuthUsersRetrieve>>
	> = ({ signal }) => apiAuthUsersRetrieve(id, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthUsersRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiAuthUsersRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersRetrieve>>
>
export type ApiAuthUsersRetrieveQueryError = unknown

export const useApiAuthUsersRetrieve = <
	TData = Awaited<ReturnType<typeof apiAuthUsersRetrieve>>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiAuthUsersRetrieve>>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiAuthUsersRetrieveQueryOptions(id, options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

export const apiAuthUsersUpdate = (id: number, user: NonReadonly<User>) => {
	return apiWithTokenAuth<User>({
		url: `/api/auth/users/${id}/`,
		method: "PUT",
		headers: { "Content-Type": "application/json" },
		data: user,
	})
}

export const getApiAuthUsersUpdateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersUpdate>>,
		TError,
		{ id: number; data: NonReadonly<User> },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersUpdate>>,
	TError,
	{ id: number; data: NonReadonly<User> },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersUpdate>>,
		{ id: number; data: NonReadonly<User> }
	> = (props) => {
		const { id, data } = props ?? {}

		return apiAuthUsersUpdate(id, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersUpdateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersUpdate>>
>
export type ApiAuthUsersUpdateMutationBody = NonReadonly<User>
export type ApiAuthUsersUpdateMutationError = unknown

export const useApiAuthUsersUpdate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersUpdate>>,
		TError,
		{ id: number; data: NonReadonly<User> },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersUpdate>>,
	TError,
	{ id: number; data: NonReadonly<User> },
	TContext
> => {
	const mutationOptions = getApiAuthUsersUpdateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersPartialUpdate = (
	id: number,
	patchedUser: NonReadonly<PatchedUser>,
) => {
	return apiWithTokenAuth<User>({
		url: `/api/auth/users/${id}/`,
		method: "PATCH",
		headers: { "Content-Type": "application/json" },
		data: patchedUser,
	})
}

export const getApiAuthUsersPartialUpdateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersPartialUpdate>>,
		TError,
		{ id: number; data: NonReadonly<PatchedUser> },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersPartialUpdate>>,
	TError,
	{ id: number; data: NonReadonly<PatchedUser> },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersPartialUpdate>>,
		{ id: number; data: NonReadonly<PatchedUser> }
	> = (props) => {
		const { id, data } = props ?? {}

		return apiAuthUsersPartialUpdate(id, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersPartialUpdateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersPartialUpdate>>
>
export type ApiAuthUsersPartialUpdateMutationBody = NonReadonly<PatchedUser>
export type ApiAuthUsersPartialUpdateMutationError = unknown

export const useApiAuthUsersPartialUpdate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersPartialUpdate>>,
		TError,
		{ id: number; data: NonReadonly<PatchedUser> },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersPartialUpdate>>,
	TError,
	{ id: number; data: NonReadonly<PatchedUser> },
	TContext
> => {
	const mutationOptions = getApiAuthUsersPartialUpdateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersDestroy = (id: number) => {
	return apiWithTokenAuth<void>({
		url: `/api/auth/users/${id}/`,
		method: "DELETE",
	})
}

export const getApiAuthUsersDestroyMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersDestroy>>,
		TError,
		{ id: number },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersDestroy>>,
	TError,
	{ id: number },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersDestroy>>,
		{ id: number }
	> = (props) => {
		const { id } = props ?? {}

		return apiAuthUsersDestroy(id)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersDestroyMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersDestroy>>
>

export type ApiAuthUsersDestroyMutationError = unknown

export const useApiAuthUsersDestroy = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersDestroy>>,
		TError,
		{ id: number },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersDestroy>>,
	TError,
	{ id: number },
	TContext
> => {
	const mutationOptions = getApiAuthUsersDestroyMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersActivationCreate = (activation: Activation) => {
	return apiWithTokenAuth<Activation>({
		url: `/api/auth/users/activation/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: activation,
	})
}

export const getApiAuthUsersActivationCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersActivationCreate>>,
		TError,
		{ data: Activation },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersActivationCreate>>,
	TError,
	{ data: Activation },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersActivationCreate>>,
		{ data: Activation }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersActivationCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersActivationCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersActivationCreate>>
>
export type ApiAuthUsersActivationCreateMutationBody = Activation
export type ApiAuthUsersActivationCreateMutationError = unknown

export const useApiAuthUsersActivationCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersActivationCreate>>,
		TError,
		{ data: Activation },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersActivationCreate>>,
	TError,
	{ data: Activation },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersActivationCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersMeRetrieve = (signal?: AbortSignal) => {
	return apiWithTokenAuth<User>({
		url: `/api/auth/users/me/`,
		method: "GET",
		signal,
	})
}

export const getApiAuthUsersMeRetrieveQueryKey = () => {
	return [`/api/auth/users/me/`] as const
}

export const getApiAuthUsersMeRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiAuthUsersMeRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthUsersMeRetrieve>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getApiAuthUsersMeRetrieveQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiAuthUsersMeRetrieve>>
	> = ({ signal }) => apiAuthUsersMeRetrieve(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthUsersMeRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiAuthUsersMeRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersMeRetrieve>>
>
export type ApiAuthUsersMeRetrieveQueryError = unknown

export const useApiAuthUsersMeRetrieve = <
	TData = Awaited<ReturnType<typeof apiAuthUsersMeRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiAuthUsersMeRetrieve>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiAuthUsersMeRetrieveQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

export const apiAuthUsersMeUpdate = (user: NonReadonly<User>) => {
	return apiWithTokenAuth<User>({
		url: `/api/auth/users/me/`,
		method: "PUT",
		headers: { "Content-Type": "application/json" },
		data: user,
	})
}

export const getApiAuthUsersMeUpdateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersMeUpdate>>,
		TError,
		{ data: NonReadonly<User> },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersMeUpdate>>,
	TError,
	{ data: NonReadonly<User> },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersMeUpdate>>,
		{ data: NonReadonly<User> }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersMeUpdate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersMeUpdateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersMeUpdate>>
>
export type ApiAuthUsersMeUpdateMutationBody = NonReadonly<User>
export type ApiAuthUsersMeUpdateMutationError = unknown

export const useApiAuthUsersMeUpdate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersMeUpdate>>,
		TError,
		{ data: NonReadonly<User> },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersMeUpdate>>,
	TError,
	{ data: NonReadonly<User> },
	TContext
> => {
	const mutationOptions = getApiAuthUsersMeUpdateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersMePartialUpdate = (
	patchedUser: NonReadonly<PatchedUser>,
) => {
	return apiWithTokenAuth<User>({
		url: `/api/auth/users/me/`,
		method: "PATCH",
		headers: { "Content-Type": "application/json" },
		data: patchedUser,
	})
}

export const getApiAuthUsersMePartialUpdateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersMePartialUpdate>>,
		TError,
		{ data: NonReadonly<PatchedUser> },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersMePartialUpdate>>,
	TError,
	{ data: NonReadonly<PatchedUser> },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersMePartialUpdate>>,
		{ data: NonReadonly<PatchedUser> }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersMePartialUpdate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersMePartialUpdateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersMePartialUpdate>>
>
export type ApiAuthUsersMePartialUpdateMutationBody = NonReadonly<PatchedUser>
export type ApiAuthUsersMePartialUpdateMutationError = unknown

export const useApiAuthUsersMePartialUpdate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersMePartialUpdate>>,
		TError,
		{ data: NonReadonly<PatchedUser> },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersMePartialUpdate>>,
	TError,
	{ data: NonReadonly<PatchedUser> },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersMePartialUpdateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersMeDestroy = () => {
	return apiWithTokenAuth<void>({
		url: `/api/auth/users/me/`,
		method: "DELETE",
	})
}

export const getApiAuthUsersMeDestroyMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersMeDestroy>>,
		TError,
		void,
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersMeDestroy>>,
	TError,
	void,
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersMeDestroy>>,
		void
	> = () => {
		return apiAuthUsersMeDestroy()
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersMeDestroyMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersMeDestroy>>
>

export type ApiAuthUsersMeDestroyMutationError = unknown

export const useApiAuthUsersMeDestroy = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersMeDestroy>>,
		TError,
		void,
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersMeDestroy>>,
	TError,
	void,
	TContext
> => {
	const mutationOptions = getApiAuthUsersMeDestroyMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersResendActivationCreate = (
	sendEmailReset: SendEmailReset,
) => {
	return apiWithTokenAuth<SendEmailReset>({
		url: `/api/auth/users/resend_activation/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: sendEmailReset,
	})
}

export const getApiAuthUsersResendActivationCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResendActivationCreate>>,
		TError,
		{ data: SendEmailReset },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersResendActivationCreate>>,
	TError,
	{ data: SendEmailReset },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersResendActivationCreate>>,
		{ data: SendEmailReset }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersResendActivationCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersResendActivationCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersResendActivationCreate>>
>
export type ApiAuthUsersResendActivationCreateMutationBody = SendEmailReset
export type ApiAuthUsersResendActivationCreateMutationError = unknown

export const useApiAuthUsersResendActivationCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResendActivationCreate>>,
		TError,
		{ data: SendEmailReset },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersResendActivationCreate>>,
	TError,
	{ data: SendEmailReset },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersResendActivationCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersResetEmailCreate = (
	sendEmailReset: SendEmailReset,
) => {
	return apiWithTokenAuth<SendEmailReset>({
		url: `/api/auth/users/reset_email/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: sendEmailReset,
	})
}

export const getApiAuthUsersResetEmailCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetEmailCreate>>,
		TError,
		{ data: SendEmailReset },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersResetEmailCreate>>,
	TError,
	{ data: SendEmailReset },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersResetEmailCreate>>,
		{ data: SendEmailReset }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersResetEmailCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersResetEmailCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersResetEmailCreate>>
>
export type ApiAuthUsersResetEmailCreateMutationBody = SendEmailReset
export type ApiAuthUsersResetEmailCreateMutationError = unknown

export const useApiAuthUsersResetEmailCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetEmailCreate>>,
		TError,
		{ data: SendEmailReset },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersResetEmailCreate>>,
	TError,
	{ data: SendEmailReset },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersResetEmailCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersResetEmailConfirmCreate = (
	usernameResetConfirm: UsernameResetConfirm,
) => {
	return apiWithTokenAuth<UsernameResetConfirm>({
		url: `/api/auth/users/reset_email_confirm/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: usernameResetConfirm,
	})
}

export const getApiAuthUsersResetEmailConfirmCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetEmailConfirmCreate>>,
		TError,
		{ data: UsernameResetConfirm },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersResetEmailConfirmCreate>>,
	TError,
	{ data: UsernameResetConfirm },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersResetEmailConfirmCreate>>,
		{ data: UsernameResetConfirm }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersResetEmailConfirmCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersResetEmailConfirmCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersResetEmailConfirmCreate>>
>
export type ApiAuthUsersResetEmailConfirmCreateMutationBody =
	UsernameResetConfirm
export type ApiAuthUsersResetEmailConfirmCreateMutationError = unknown

export const useApiAuthUsersResetEmailConfirmCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetEmailConfirmCreate>>,
		TError,
		{ data: UsernameResetConfirm },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersResetEmailConfirmCreate>>,
	TError,
	{ data: UsernameResetConfirm },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersResetEmailConfirmCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersResetPasswordCreate = (
	sendEmailReset: SendEmailReset,
) => {
	return apiWithTokenAuth<SendEmailReset>({
		url: `/api/auth/users/reset_password/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: sendEmailReset,
	})
}

export const getApiAuthUsersResetPasswordCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetPasswordCreate>>,
		TError,
		{ data: SendEmailReset },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersResetPasswordCreate>>,
	TError,
	{ data: SendEmailReset },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersResetPasswordCreate>>,
		{ data: SendEmailReset }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersResetPasswordCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersResetPasswordCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersResetPasswordCreate>>
>
export type ApiAuthUsersResetPasswordCreateMutationBody = SendEmailReset
export type ApiAuthUsersResetPasswordCreateMutationError = unknown

export const useApiAuthUsersResetPasswordCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetPasswordCreate>>,
		TError,
		{ data: SendEmailReset },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersResetPasswordCreate>>,
	TError,
	{ data: SendEmailReset },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersResetPasswordCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersResetPasswordConfirmCreate = (
	passwordResetConfirmRetype: PasswordResetConfirmRetype,
) => {
	return apiWithTokenAuth<PasswordResetConfirmRetype>({
		url: `/api/auth/users/reset_password_confirm/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: passwordResetConfirmRetype,
	})
}

export const getApiAuthUsersResetPasswordConfirmCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetPasswordConfirmCreate>>,
		TError,
		{ data: PasswordResetConfirmRetype },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersResetPasswordConfirmCreate>>,
	TError,
	{ data: PasswordResetConfirmRetype },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersResetPasswordConfirmCreate>>,
		{ data: PasswordResetConfirmRetype }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersResetPasswordConfirmCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersResetPasswordConfirmCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersResetPasswordConfirmCreate>>
>
export type ApiAuthUsersResetPasswordConfirmCreateMutationBody =
	PasswordResetConfirmRetype
export type ApiAuthUsersResetPasswordConfirmCreateMutationError = unknown

export const useApiAuthUsersResetPasswordConfirmCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersResetPasswordConfirmCreate>>,
		TError,
		{ data: PasswordResetConfirmRetype },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersResetPasswordConfirmCreate>>,
	TError,
	{ data: PasswordResetConfirmRetype },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersResetPasswordConfirmCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersSetEmailCreate = (setUsername: SetUsername) => {
	return apiWithTokenAuth<SetUsername>({
		url: `/api/auth/users/set_email/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: setUsername,
	})
}

export const getApiAuthUsersSetEmailCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersSetEmailCreate>>,
		TError,
		{ data: SetUsername },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersSetEmailCreate>>,
	TError,
	{ data: SetUsername },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersSetEmailCreate>>,
		{ data: SetUsername }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersSetEmailCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersSetEmailCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersSetEmailCreate>>
>
export type ApiAuthUsersSetEmailCreateMutationBody = SetUsername
export type ApiAuthUsersSetEmailCreateMutationError = unknown

export const useApiAuthUsersSetEmailCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersSetEmailCreate>>,
		TError,
		{ data: SetUsername },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersSetEmailCreate>>,
	TError,
	{ data: SetUsername },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersSetEmailCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiAuthUsersSetPasswordCreate = (
	setPasswordRetype: SetPasswordRetype,
) => {
	return apiWithTokenAuth<SetPasswordRetype>({
		url: `/api/auth/users/set_password/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: setPasswordRetype,
	})
}

export const getApiAuthUsersSetPasswordCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersSetPasswordCreate>>,
		TError,
		{ data: SetPasswordRetype },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiAuthUsersSetPasswordCreate>>,
	TError,
	{ data: SetPasswordRetype },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiAuthUsersSetPasswordCreate>>,
		{ data: SetPasswordRetype }
	> = (props) => {
		const { data } = props ?? {}

		return apiAuthUsersSetPasswordCreate(data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiAuthUsersSetPasswordCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiAuthUsersSetPasswordCreate>>
>
export type ApiAuthUsersSetPasswordCreateMutationBody = SetPasswordRetype
export type ApiAuthUsersSetPasswordCreateMutationError = unknown

export const useApiAuthUsersSetPasswordCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiAuthUsersSetPasswordCreate>>,
		TError,
		{ data: SetPasswordRetype },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiAuthUsersSetPasswordCreate>>,
	TError,
	{ data: SetPasswordRetype },
	TContext
> => {
	const mutationOptions =
		getApiAuthUsersSetPasswordCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
/**
 * OpenApi3 schema for this API. Format can be selected via content negotiation.

- YAML: application/vnd.oai.openapi
- JSON: application/vnd.oai.openapi+json
 */
export const apiSchemaRetrieve = (
	params?: ApiSchemaRetrieveParams,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<
		| ApiSchemaRetrieve200One
		| ApiSchemaRetrieve200Two
		| ApiSchemaRetrieve200Three
		| ApiSchemaRetrieve200Four
	>({ url: `/api/schema/`, method: "GET", params, signal })
}

export const getApiSchemaRetrieveQueryKey = (
	params?: ApiSchemaRetrieveParams,
) => {
	return [`/api/schema/`, ...(params ? [params] : [])] as const
}

export const getApiSchemaRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiSchemaRetrieve>>,
	TError = unknown,
>(
	params?: ApiSchemaRetrieveParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiSchemaRetrieve>>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getApiSchemaRetrieveQueryKey(params)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiSchemaRetrieve>>
	> = ({ signal }) => apiSchemaRetrieve(params, signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiSchemaRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiSchemaRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiSchemaRetrieve>>
>
export type ApiSchemaRetrieveQueryError = unknown

export const useApiSchemaRetrieve = <
	TData = Awaited<ReturnType<typeof apiSchemaRetrieve>>,
	TError = unknown,
>(
	params?: ApiSchemaRetrieveParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiSchemaRetrieve>>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiSchemaRetrieveQueryOptions(params, options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * API view with client details: their sensors, panels and home type.

Note that this includes sensor tokens, so this is sensitive information.
 */
export const apiV1ClientRetrieve = (signal?: AbortSignal) => {
	return apiWithTokenAuth<void>({
		url: `/api/v1/client/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ClientRetrieveQueryKey = () => {
	return [`/api/v1/client/`] as const
}

export const getApiV1ClientRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1ClientRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ClientRetrieve>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey = queryOptions?.queryKey ?? getApiV1ClientRetrieveQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ClientRetrieve>>
	> = ({ signal }) => apiV1ClientRetrieve(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ClientRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ClientRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ClientRetrieve>>
>
export type ApiV1ClientRetrieveQueryError = unknown

export const useApiV1ClientRetrieve = <
	TData = Awaited<ReturnType<typeof apiV1ClientRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ClientRetrieve>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiV1ClientRetrieveQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * API view for a client's production in a given timeframe.

Returns a single value, the generated Wh by this client in this timeframe.
 */
export const apiV1ClientProductionAggregateRetrieve = (
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<void>({
		url: `/api/v1/client/production/aggregate/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ClientProductionAggregateRetrieveQueryKey = () => {
	return [`/api/v1/client/production/aggregate/`] as const
}

export const getApiV1ClientProductionAggregateRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1ClientProductionAggregateRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ClientProductionAggregateRetrieve>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1ClientProductionAggregateRetrieveQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ClientProductionAggregateRetrieve>>
	> = ({ signal }) => apiV1ClientProductionAggregateRetrieve(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ClientProductionAggregateRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ClientProductionAggregateRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ClientProductionAggregateRetrieve>>
>
export type ApiV1ClientProductionAggregateRetrieveQueryError = unknown

export const useApiV1ClientProductionAggregateRetrieve = <
	TData = Awaited<ReturnType<typeof apiV1ClientProductionAggregateRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ClientProductionAggregateRetrieve>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions =
		getApiV1ClientProductionAggregateRetrieveQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * An API view for returning current Endex price
 */
export const apiV1ComparisonProjectEndexPriceRetrieve = (
	id: number,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<EndexPrice>({
		url: `/api/v1/comparison/project/${id}/endex-price/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ComparisonProjectEndexPriceRetrieveQueryKey = (
	id: number,
) => {
	return [`/api/v1/comparison/project/${id}/endex-price/`] as const
}

export const getApiV1ComparisonProjectEndexPriceRetrieveQueryOptions = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectEndexPriceRetrieve>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectEndexPriceRetrieve>
			>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1ComparisonProjectEndexPriceRetrieveQueryKey(id)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ComparisonProjectEndexPriceRetrieve>>
	> = ({ signal }) => apiV1ComparisonProjectEndexPriceRetrieve(id, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ComparisonProjectEndexPriceRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ComparisonProjectEndexPriceRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ComparisonProjectEndexPriceRetrieve>>
>
export type ApiV1ComparisonProjectEndexPriceRetrieveQueryError = unknown

export const useApiV1ComparisonProjectEndexPriceRetrieve = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectEndexPriceRetrieve>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectEndexPriceRetrieve>
			>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions =
		getApiV1ComparisonProjectEndexPriceRetrieveQueryOptions(id, options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * API view that shows all endex tariffs for a specific project's interestperiods
 */
export const apiV1ComparisonProjectEndexProgressRetrieve = (
	id: number,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<EndexProgress>({
		url: `/api/v1/comparison/project/${id}/endex-progress/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ComparisonProjectEndexProgressRetrieveQueryKey = (
	id: number,
) => {
	return [`/api/v1/comparison/project/${id}/endex-progress/`] as const
}

export const getApiV1ComparisonProjectEndexProgressRetrieveQueryOptions = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectEndexProgressRetrieve>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectEndexProgressRetrieve>
			>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1ComparisonProjectEndexProgressRetrieveQueryKey(id)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ComparisonProjectEndexProgressRetrieve>>
	> = ({ signal }) => apiV1ComparisonProjectEndexProgressRetrieve(id, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ComparisonProjectEndexProgressRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ComparisonProjectEndexProgressRetrieveQueryResult =
	NonNullable<
		Awaited<ReturnType<typeof apiV1ComparisonProjectEndexProgressRetrieve>>
	>
export type ApiV1ComparisonProjectEndexProgressRetrieveQueryError = unknown

export const useApiV1ComparisonProjectEndexProgressRetrieve = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectEndexProgressRetrieve>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectEndexProgressRetrieve>
			>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions =
		getApiV1ComparisonProjectEndexProgressRetrieveQueryOptions(id, options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * API view that shows all endex tariffs for a specific project's interestperiods
 */
export const apiV1ComparisonProjectEndexTariffsList = (
	id: number,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<EndexTariff[]>({
		url: `/api/v1/comparison/project/${id}/endex-tariffs/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ComparisonProjectEndexTariffsListQueryKey = (
	id: number,
) => {
	return [`/api/v1/comparison/project/${id}/endex-tariffs/`] as const
}

export const getApiV1ComparisonProjectEndexTariffsListQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1ComparisonProjectEndexTariffsList>>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiV1ComparisonProjectEndexTariffsList>>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1ComparisonProjectEndexTariffsListQueryKey(id)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ComparisonProjectEndexTariffsList>>
	> = ({ signal }) => apiV1ComparisonProjectEndexTariffsList(id, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ComparisonProjectEndexTariffsList>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ComparisonProjectEndexTariffsListQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ComparisonProjectEndexTariffsList>>
>
export type ApiV1ComparisonProjectEndexTariffsListQueryError = unknown

export const useApiV1ComparisonProjectEndexTariffsList = <
	TData = Awaited<ReturnType<typeof apiV1ComparisonProjectEndexTariffsList>>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiV1ComparisonProjectEndexTariffsList>>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiV1ComparisonProjectEndexTariffsListQueryOptions(
		id,
		options,
	)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * API view for getting data for live performance graph.
 */
export const apiV1ComparisonProjectLivePerformanceList = (
	id: number,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<LivePerformance[]>({
		url: `/api/v1/comparison/project/${id}/live-performance/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ComparisonProjectLivePerformanceListQueryKey = (
	id: number,
) => {
	return [`/api/v1/comparison/project/${id}/live-performance/`] as const
}

export const getApiV1ComparisonProjectLivePerformanceListQueryOptions = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectLivePerformanceList>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectLivePerformanceList>
			>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1ComparisonProjectLivePerformanceListQueryKey(id)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ComparisonProjectLivePerformanceList>>
	> = ({ signal }) => apiV1ComparisonProjectLivePerformanceList(id, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ComparisonProjectLivePerformanceList>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ComparisonProjectLivePerformanceListQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ComparisonProjectLivePerformanceList>>
>
export type ApiV1ComparisonProjectLivePerformanceListQueryError = unknown

export const useApiV1ComparisonProjectLivePerformanceList = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectLivePerformanceList>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectLivePerformanceList>
			>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions =
		getApiV1ComparisonProjectLivePerformanceListQueryOptions(id, options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * API view for getting data for production factor table.
 */
export const apiV1ComparisonProjectProductionFactorList = (
	id: number,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<ProductionFactor[]>({
		url: `/api/v1/comparison/project/${id}/production-factor/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ComparisonProjectProductionFactorListQueryKey = (
	id: number,
) => {
	return [`/api/v1/comparison/project/${id}/production-factor/`] as const
}

export const getApiV1ComparisonProjectProductionFactorListQueryOptions = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectProductionFactorList>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectProductionFactorList>
			>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1ComparisonProjectProductionFactorListQueryKey(id)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ComparisonProjectProductionFactorList>>
	> = ({ signal }) => apiV1ComparisonProjectProductionFactorList(id, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ComparisonProjectProductionFactorList>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ComparisonProjectProductionFactorListQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ComparisonProjectProductionFactorList>>
>
export type ApiV1ComparisonProjectProductionFactorListQueryError = unknown

export const useApiV1ComparisonProjectProductionFactorList = <
	TData = Awaited<
		ReturnType<typeof apiV1ComparisonProjectProductionFactorList>
	>,
	TError = unknown,
>(
	id: number,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1ComparisonProjectProductionFactorList>
			>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions =
		getApiV1ComparisonProjectProductionFactorListQueryOptions(id, options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * API view for the timestamp of the last KACO measurement in the DB.

Used for automatic monitoring of insertion progress.
 */
export const apiV1MeasurementKacoLastTimestampRetrieve = (
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<void>({
		url: `/api/v1/measurement/kaco/last_timestamp/`,
		method: "GET",
		signal,
	})
}

export const getApiV1MeasurementKacoLastTimestampRetrieveQueryKey = () => {
	return [`/api/v1/measurement/kaco/last_timestamp/`] as const
}

export const getApiV1MeasurementKacoLastTimestampRetrieveQueryOptions = <
	TData = Awaited<
		ReturnType<typeof apiV1MeasurementKacoLastTimestampRetrieve>
	>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1MeasurementKacoLastTimestampRetrieve>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1MeasurementKacoLastTimestampRetrieveQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1MeasurementKacoLastTimestampRetrieve>>
	> = ({ signal }) => apiV1MeasurementKacoLastTimestampRetrieve(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1MeasurementKacoLastTimestampRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1MeasurementKacoLastTimestampRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1MeasurementKacoLastTimestampRetrieve>>
>
export type ApiV1MeasurementKacoLastTimestampRetrieveQueryError = unknown

export const useApiV1MeasurementKacoLastTimestampRetrieve = <
	TData = Awaited<
		ReturnType<typeof apiV1MeasurementKacoLastTimestampRetrieve>
	>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1MeasurementKacoLastTimestampRetrieve>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions =
		getApiV1MeasurementKacoLastTimestampRetrieveQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * A method to get all clients and their related data for ProjectOwner confirmation
 */
export const apiV1PaymentOwnerVerificationRetrieve = (
	id: number,
	params?: ApiV1PaymentOwnerVerificationRetrieveParams,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<VerificationPayment>({
		url: `/api/v1/payment/${id}/owner-verification/`,
		method: "GET",
		params,
		signal,
	})
}

export const getApiV1PaymentOwnerVerificationRetrieveQueryKey = (
	id: number,
	params?: ApiV1PaymentOwnerVerificationRetrieveParams,
) => {
	return [
		`/api/v1/payment/${id}/owner-verification/`,
		...(params ? [params] : []),
	] as const
}

export const getApiV1PaymentOwnerVerificationRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationRetrieve>>,
	TError = unknown,
>(
	id: number,
	params?: ApiV1PaymentOwnerVerificationRetrieveParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationRetrieve>>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1PaymentOwnerVerificationRetrieveQueryKey(id, params)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationRetrieve>>
	> = ({ signal }) =>
		apiV1PaymentOwnerVerificationRetrieve(id, params, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1PaymentOwnerVerificationRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationRetrieve>>
>
export type ApiV1PaymentOwnerVerificationRetrieveQueryError = unknown

export const useApiV1PaymentOwnerVerificationRetrieve = <
	TData = Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationRetrieve>>,
	TError = unknown,
>(
	id: number,
	params?: ApiV1PaymentOwnerVerificationRetrieveParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationRetrieve>>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiV1PaymentOwnerVerificationRetrieveQueryOptions(
		id,
		params,
		options,
	)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * A method to accept or reject the payment for the ProjectOwner
 */
export const apiV1PaymentOwnerVerificationCreate = (
	id: number,
	ownerVerification: OwnerVerification,
) => {
	return apiWithTokenAuth<OwnerVerificationResponse>({
		url: `/api/v1/payment/${id}/owner-verification/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: ownerVerification,
	})
}

export const getApiV1PaymentOwnerVerificationCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationCreate>>,
		TError,
		{ id: number; data: OwnerVerification },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationCreate>>,
	TError,
	{ id: number; data: OwnerVerification },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationCreate>>,
		{ id: number; data: OwnerVerification }
	> = (props) => {
		const { id, data } = props ?? {}

		return apiV1PaymentOwnerVerificationCreate(id, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiV1PaymentOwnerVerificationCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationCreate>>
>
export type ApiV1PaymentOwnerVerificationCreateMutationBody = OwnerVerification
export type ApiV1PaymentOwnerVerificationCreateMutationError = unknown

export const useApiV1PaymentOwnerVerificationCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationCreate>>,
		TError,
		{ id: number; data: OwnerVerification },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiV1PaymentOwnerVerificationCreate>>,
	TError,
	{ id: number; data: OwnerVerification },
	TContext
> => {
	const mutationOptions =
		getApiV1PaymentOwnerVerificationCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
/**
 * A method to get all clients and their related data for ProjectOwner confirmation
 */
export const apiV1PaymentSupplierVerificationRetrieve = (
	id: number,
	params?: ApiV1PaymentSupplierVerificationRetrieveParams,
	signal?: AbortSignal,
) => {
	return apiWithTokenAuth<VerificationPayment>({
		url: `/api/v1/payment/${id}/supplier-verification/`,
		method: "GET",
		params,
		signal,
	})
}

export const getApiV1PaymentSupplierVerificationRetrieveQueryKey = (
	id: number,
	params?: ApiV1PaymentSupplierVerificationRetrieveParams,
) => {
	return [
		`/api/v1/payment/${id}/supplier-verification/`,
		...(params ? [params] : []),
	] as const
}

export const getApiV1PaymentSupplierVerificationRetrieveQueryOptions = <
	TData = Awaited<
		ReturnType<typeof apiV1PaymentSupplierVerificationRetrieve>
	>,
	TError = unknown,
>(
	id: number,
	params?: ApiV1PaymentSupplierVerificationRetrieveParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1PaymentSupplierVerificationRetrieve>
			>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ??
		getApiV1PaymentSupplierVerificationRetrieveQueryKey(id, params)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationRetrieve>>
	> = ({ signal }) =>
		apiV1PaymentSupplierVerificationRetrieve(id, params, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1PaymentSupplierVerificationRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationRetrieve>>
>
export type ApiV1PaymentSupplierVerificationRetrieveQueryError = unknown

export const useApiV1PaymentSupplierVerificationRetrieve = <
	TData = Awaited<
		ReturnType<typeof apiV1PaymentSupplierVerificationRetrieve>
	>,
	TError = unknown,
>(
	id: number,
	params?: ApiV1PaymentSupplierVerificationRetrieveParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<
				ReturnType<typeof apiV1PaymentSupplierVerificationRetrieve>
			>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions =
		getApiV1PaymentSupplierVerificationRetrieveQueryOptions(
			id,
			params,
			options,
		)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * A method to accept or reject the payment for the ProjectOwner with optionally corrected payment entries
 */
export const apiV1PaymentSupplierVerificationCreate = (
	id: number,
	supplierVerification: SupplierVerification,
) => {
	return apiWithTokenAuth<SupplierVerificationResponse>({
		url: `/api/v1/payment/${id}/supplier-verification/`,
		method: "POST",
		headers: { "Content-Type": "application/json" },
		data: supplierVerification,
	})
}

export const getApiV1PaymentSupplierVerificationCreateMutationOptions = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationCreate>>,
		TError,
		{ id: number; data: SupplierVerification },
		TContext
	>
}): UseMutationOptions<
	Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationCreate>>,
	TError,
	{ id: number; data: SupplierVerification },
	TContext
> => {
	const { mutation: mutationOptions } = options ?? {}

	const mutationFn: MutationFunction<
		Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationCreate>>,
		{ id: number; data: SupplierVerification }
	> = (props) => {
		const { id, data } = props ?? {}

		return apiV1PaymentSupplierVerificationCreate(id, data)
	}

	return { mutationFn, ...mutationOptions }
}

export type ApiV1PaymentSupplierVerificationCreateMutationResult = NonNullable<
	Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationCreate>>
>
export type ApiV1PaymentSupplierVerificationCreateMutationBody =
	SupplierVerification
export type ApiV1PaymentSupplierVerificationCreateMutationError = unknown

export const useApiV1PaymentSupplierVerificationCreate = <
	TError = unknown,
	TContext = unknown,
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationCreate>>,
		TError,
		{ id: number; data: SupplierVerification },
		TContext
	>
}): UseMutationResult<
	Awaited<ReturnType<typeof apiV1PaymentSupplierVerificationCreate>>,
	TError,
	{ id: number; data: SupplierVerification },
	TContext
> => {
	const mutationOptions =
		getApiV1PaymentSupplierVerificationCreateMutationOptions(options)

	return useMutation(mutationOptions)
}
export const apiV1ProductionCounterRetrieve = (signal?: AbortSignal) => {
	return apiWithTokenAuth<void>({
		url: `/api/v1/production_counter/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ProductionCounterRetrieveQueryKey = () => {
	return [`/api/v1/production_counter/`] as const
}

export const getApiV1ProductionCounterRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1ProductionCounterRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProductionCounterRetrieve>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getApiV1ProductionCounterRetrieveQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ProductionCounterRetrieve>>
	> = ({ signal }) => apiV1ProductionCounterRetrieve(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProductionCounterRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ProductionCounterRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ProductionCounterRetrieve>>
>
export type ApiV1ProductionCounterRetrieveQueryError = unknown

export const useApiV1ProductionCounterRetrieve = <
	TData = Awaited<ReturnType<typeof apiV1ProductionCounterRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProductionCounterRetrieve>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiV1ProductionCounterRetrieveQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * Get client profile data. To be used for populating screen C2.1.

# Response values
- `client`
    - `name`
    - `email`
    - `phone` (optional)
- `latest badge` The latest user badge that has been awarded to client.
- `projects` Overview of projects the user has invested in
    - `project` Project details
    - `solar_parts_owned` Number of solar parts owned by client in this project
    - `total_nominal_value` Total nominal value of solar parts owned
    - `generated_power` Total generated power by solar parts owned
    - `power_cost_ct_kWh` Current power cost rate
    - `return_on_investment` Return on investment over period
 */
export const apiV1ProfileRetrieve = (signal?: AbortSignal) => {
	return apiWithTokenAuth<UserProfile>({
		url: `/api/v1/profile/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ProfileRetrieveQueryKey = () => {
	return [`/api/v1/profile/`] as const
}

export const getApiV1ProfileRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1ProfileRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProfileRetrieve>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey = queryOptions?.queryKey ?? getApiV1ProfileRetrieveQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ProfileRetrieve>>
	> = ({ signal }) => apiV1ProfileRetrieve(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProfileRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ProfileRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ProfileRetrieve>>
>
export type ApiV1ProfileRetrieveQueryError = unknown

export const useApiV1ProfileRetrieve = <
	TData = Awaited<ReturnType<typeof apiV1ProfileRetrieve>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProfileRetrieve>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiV1ProfileRetrieveQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * view -- If set to `live`, only realtime sensor data will be shown.
 */
export const apiV1ProjectRetrieve = (id: string, signal?: AbortSignal) => {
	return apiWithTokenAuth<ProjectDetail>({
		url: `/api/v1/project/${id}/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ProjectRetrieveQueryKey = (id: string) => {
	return [`/api/v1/project/${id}/`] as const
}

export const getApiV1ProjectRetrieveQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1ProjectRetrieve>>,
	TError = unknown,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiV1ProjectRetrieve>>,
			TError,
			TData
		>
	},
) => {
	const { query: queryOptions } = options ?? {}

	const queryKey =
		queryOptions?.queryKey ?? getApiV1ProjectRetrieveQueryKey(id)

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ProjectRetrieve>>
	> = ({ signal }) => apiV1ProjectRetrieve(id, signal)

	return {
		queryKey,
		queryFn,
		enabled: !!id,
		...queryOptions,
	} as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProjectRetrieve>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ProjectRetrieveQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ProjectRetrieve>>
>
export type ApiV1ProjectRetrieveQueryError = unknown

export const useApiV1ProjectRetrieve = <
	TData = Awaited<ReturnType<typeof apiV1ProjectRetrieve>>,
	TError = unknown,
>(
	id: string,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<typeof apiV1ProjectRetrieve>>,
			TError,
			TData
		>
	},
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiV1ProjectRetrieveQueryOptions(id, options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

/**
 * view -- If set to `index_only`, the `metrics` block is not returned, leading to better performance
 */
export const apiV1ProjectsList = (signal?: AbortSignal) => {
	return apiWithTokenAuth<ProjectList[]>({
		url: `/api/v1/projects/`,
		method: "GET",
		signal,
	})
}

export const getApiV1ProjectsListQueryKey = () => {
	return [`/api/v1/projects/`] as const
}

export const getApiV1ProjectsListQueryOptions = <
	TData = Awaited<ReturnType<typeof apiV1ProjectsList>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProjectsList>>,
		TError,
		TData
	>
}) => {
	const { query: queryOptions } = options ?? {}

	const queryKey = queryOptions?.queryKey ?? getApiV1ProjectsListQueryKey()

	const queryFn: QueryFunction<
		Awaited<ReturnType<typeof apiV1ProjectsList>>
	> = ({ signal }) => apiV1ProjectsList(signal)

	return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProjectsList>>,
		TError,
		TData
	> & { queryKey: QueryKey }
}

export type ApiV1ProjectsListQueryResult = NonNullable<
	Awaited<ReturnType<typeof apiV1ProjectsList>>
>
export type ApiV1ProjectsListQueryError = unknown

export const useApiV1ProjectsList = <
	TData = Awaited<ReturnType<typeof apiV1ProjectsList>>,
	TError = unknown,
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<typeof apiV1ProjectsList>>,
		TError,
		TData
	>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const queryOptions = getApiV1ProjectsListQueryOptions(options)

	const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
		queryKey: QueryKey
	}

	query.queryKey = queryOptions.queryKey

	return query
}

// React
import { useState } from "react"

// Maps
import {
	APIProvider,
	Map,
	AdvancedMarker,
	InfoWindow,
	useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps"

// GraphQL
import { useFinanceDashboardMapsQuery, PublicProjectType } from "@/api/graphql"

// Images
import ImageMapsMarkerInstallation from "../../../assets/icons/maps_marker_installation.svg"

// Env variables
const GOOGLE_MAPS_API_KEY: string =
	process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
const GOOGLE_MAPS_ID: string = process.env.REACT_APP_GOOGLE_MAPS_ID || ""

const MapTypeId = {
	HYBRID: "hybrid",
	ROADMAP: "roadmap",
	SATELLITE: "satellite",
	TERRAIN: "terrain",
}

interface MapsProps {
	center: google.maps.LatLngLiteral
	zoom: number
	points?: Array<PublicProjectType | undefined | null> | undefined | null
}

/**
 * FinanceProjectsMapsOverview
 * @param param0
 * @returns
 */
export function FinanceProjectsMapsOverview({ center, zoom }: MapsProps) {
	// Fetch projects
	const { data } = useFinanceDashboardMapsQuery({
		latitude: String(center.lat),
		longitude: String(center.lng),
		radius: "500",
	})

	return (
		<APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={["marker"]}>
			<Map
				mapId={GOOGLE_MAPS_ID}
				mapTypeId={MapTypeId.ROADMAP}
				className="h-full w-full"
				defaultCenter={center}
				defaultZoom={zoom}
				gestureHandling={"scroll"}
				mapTypeControl={false}
				zoomControl={true}
				fullscreenControl={false}
				streetViewControl={false}
				styles={[
					// Copied style directly from Angular zonnepanelendelen.nl/dashboard
					{
						featureType: "administrative",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#444444",
							},
						],
					},
					{
						featureType: "landscape",
						elementType: "all",
						stylers: [
							{
								color: "#f2f2f2",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "road",
						elementType: "all",
						stylers: [
							{
								saturation: -100,
							},
							{
								lightness: 45,
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "all",
						stylers: [
							{
								visibility: "simplified",
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "transit",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "water",
						elementType: "all",
						stylers: [
							{
								color: "#d3d3d3",
							},
							{
								visibility: "on",
							},
						],
					},
				]}
			>
				{/** Show all points of interest on the map */}
				{data?.public_projects?.results?.map((point) => (
					<>
						{point?.latitude && point?.longitude && (
							<MarkerWithInfowindow
								key={point?.id}
								name={point?.name || ""}
								position={{
									lat: point.latitude,
									lng: point.longitude,
								}}
							/>
						)}
					</>
				))}
			</Map>
		</APIProvider>
	)
}

/**
 * MarkerWithInfowindow
 *
 * DOCS: https://developers.google.com/maps/documentation/get-map-id
 * DOCS: https://developers.google.com/maps/documentation/javascript/advanced-markers/migration
 *
 * @returns
 */
export const MarkerWithInfowindow = ({
	position,
	name,
}: {
	position: google.maps.LatLngLiteral
	name: string
}) => {
	const [infowindowOpen, setInfowindowOpen] = useState(false)
	const [markerRef, marker] = useAdvancedMarkerRef()

	return (
		<>
			<AdvancedMarker
				ref={markerRef}
				onClick={() => setInfowindowOpen(true)}
				position={position}
				title={name}
			>
				<img src={ImageMapsMarkerInstallation} alt={name} />
			</AdvancedMarker>
			{infowindowOpen && (
				<InfoWindow
					anchor={marker}
					maxWidth={200}
					onCloseClick={() => setInfowindowOpen(false)}
				>
					Project: <strong>{name}</strong>
				</InfoWindow>
			)}
		</>
	)
}

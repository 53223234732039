import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useMemo,
} from "react"

// DateTime
import { setLang as setD3Lang } from "@/lib/charts"
import { DateTime } from "@/lib/dates"

// Translations
import { useTranslation } from "react-i18next"
import { configs, defaultLanguage, LangConfig, Languages } from "@/i18n/config"
import i18nISOCountries from "i18n-iso-countries"

// State
import { useSelector, useDispatch } from "@/state/StateProvider"
import {
	UserPreferencesState,
	setLang,
} from "@/state/features/userPreferencesSlice"

// Types
type FormatNumberType = (
	number: number | bigint,
	options?: Intl.NumberFormatOptions | undefined,
) => string
interface LangContextType {
	config: LangConfig
	lang: Languages
	formatNumber: FormatNumberType
	formatCurrency: Intl.NumberFormat["format"]
	formatDate: (
		date: DateTime,
		options?: { variant?: "pretty" | "verbose" | "computer" },
	) => string
	setLang: (newLang: Languages) => void
	getCountryName: (country: string) => string | null
}

const LangContext = createContext<LangContextType>(null!)

interface LanguageProviderProps {
	lang?: Languages
	children: ReactNode | ((value: LangContextType) => ReactNode)
}

const registeredMap = new Set<string>()

export const registerCountries = async (lang: string, attempt = 0) => {
	if (registeredMap.has(lang)) return

	try {
		i18nISOCountries.registerLocale(
			await import(`i18n-iso-countries/langs/${lang}.json`),
		)
		registeredMap.add(lang)
	} catch {
		if (attempt < 3) {
			await new Promise((r) => setTimeout(r, 1000))
			registerCountries(lang, attempt + 1)
		}
	}
}

/**
 * LanguageProvider
 * @param param0
 * @returns
 */
export const LanguageProvider = ({ children }: LanguageProviderProps) => {
	const { i18n } = useTranslation()
	const dispatch = useDispatch()

	// State
	const lang = useSelector(
		({ userPreferences }: { userPreferences: UserPreferencesState }) =>
			userPreferences.lang,
	)

	// On mount, configure all langs
	useEffect(() => {
		setLangProxy(lang)
	}, [])

	const config = configs[lang] || configs[defaultLanguage]

	// Custom format number
	const formatNumber: FormatNumberType = useMemo(
		() => (number, options) => {
			const customFormatter = new Intl.NumberFormat(
				config.locale,
				options,
			)
			return customFormatter.format(number)
		},
		[config.locale],
	)

	const currencyFormatter = useMemo(
		() =>
			new Intl.NumberFormat(config.locale, {
				style: "currency",
				currency: config.currency,
			}),
		[config.currency, config.locale],
	)

	// Execute all these functions when the locale is changed
	const setLangProxy = useCallback(
		async (lang: Languages) => {
			setD3Lang(lang) // Update D3
			i18n.changeLanguage(lang) // Update i18n
			dispatch(setLang(lang)) // Save in Redux
			await registerCountries(lang) // Register country translations
		},
		[i18n],
	)

	const getCountryName = useCallback(
		(country: string) => {
			try {
				return i18nISOCountries.getName(country, lang, {
					select: "official",
				})
			} catch (e) {
				console.error(`Could not translate country ${country}: ${e}`)
				return null
			}
		},
		[lang],
	)

	const formatDate: LangContextType["formatDate"] = useCallback(
		(date: DateTime, { variant } = {}) => {
			if (variant === "pretty") {
				return date.toLocaleString({
					locale: config.locale,
					weekday: "long",
					month: "long",
					day: "2-digit",
					year: "2-digit",
				})
			}

			// Looks like: "Sunday, 29 October 2023"
			if (variant === "verbose") {
				return date.toLocaleString({
					locale: config.locale,
					weekday: "long",
					month: "long",
					day: "2-digit",
					year: "numeric",
				})
			}

			return date.toLocaleString()
		},
		[config.locale],
	)

	const value = useMemo(
		() => ({
			config,
			lang,
			setLang: setLangProxy,
			formatNumber: formatNumber,
			formatCurrency: currencyFormatter.format,
			formatDate,
			getCountryName,
		}),
		[
			config,
			currencyFormatter.format,
			formatDate,
			getCountryName,
			lang,
			formatNumber,
			setLangProxy,
		],
	)

	return (
		<LangContext.Provider value={value}>
			{typeof children === "function" ? children(value) : children}
		</LangContext.Provider>
	)
}

export const useLang = () => useContext(LangContext)

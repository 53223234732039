import { Environment, graphMinKwhRoundThreshold } from "./constants"
import { stripSeparatorsFromWholeNumber } from "@/lib/math"

export const isProduction = (): boolean =>
	process.env.NODE_ENV === Environment.production

export const isDevelopment = (): boolean =>
	process.env.NODE_ENV === Environment.development

export const isTesting = (): boolean => process.env.JEST_WORKER_ID !== undefined

/**
 * truncate float without rounding it up or down and return new float with two decimals
 * @param value
 */
export const truncateFloatNumber = (value: number) => {
	return Math.floor(value * 100) / 100.0
}

export const emptyValue = (): string => "-"

export const formatGraphValue = (value: number | string): number => {
	const formattedValue =
		typeof value === "string"
			? parseFloat(stripSeparatorsFromWholeNumber(value))
			: value

	return formattedValue > graphMinKwhRoundThreshold
		? Math.round(formattedValue)
		: formattedValue
}

// React
import { ChangeEvent } from "react"

// UI
import { Heading } from "@/components/Typography"
import { CardBody, CardWrapper } from "@/components/Card"
import { Button } from "@/components/Button"
import { Select } from "@/components/form-controls"

// State
import { useDispatch, useSelector } from "@/state/StateProvider"
import { AuthSliceState } from "@/state/features/authSlice"
import { UserPreferencesState } from "@/state/features/userPreferencesSlice"
import {
	setPerPage,
	setFilterState,
	setFilterType,
	setHiddenIds,
	setSortingMethod,
	setSortingOrder,
	setViewType,
} from "@/state/features/investmentsOverview/slice"

// Types
import {
	InvestmentsOverviewState,
	ProjectPageViewTypes,
	projectPageViewTypes,
	projectSortMethod,
	ProjectSortOrder,
	projectPageSizes,
	ProjectSortMethod,
	ProjectPageSize,
	ProjectStateEnumFiltered,
	ProjectTypeEnumFiltered,
} from "@/state/features/investmentsOverview/types"

// Icons
import { FiChevronDown } from "@/lib/icons"

// Translations
import { useLang } from "@/context/lang"
import { Languages, configs } from "@/i18n/config"
import { useTrans } from "@/i18n"

// Icons
import { ChevronDownIcon } from "@heroicons/react/outline"

// UI
// Animations
import {
	motion,
	AnimatePresence,
	getTransitionPopoverProps,
} from "@/lib/animations"
import { Popover, Transition } from "@headlessui/react"
import { Radio } from "@/components/form-controls"
import { P } from "@/components/Typography"

/**
 * AppSettings
 * @returns
 */
export const AppSettings = () => {
	const t = useTrans(["profile", "investments"])
	const dispatch = useDispatch()
	const { setLang } = useLang()

	// State
	const auth = useSelector(({ auth }: { auth: AuthSliceState }) => auth)
	const userPreferences = useSelector(
		({ userPreferences }: { userPreferences: UserPreferencesState }) =>
			userPreferences,
	)
	const investmentsOverview = useSelector(
		({
			investmentsOverview,
		}: {
			investmentsOverview: InvestmentsOverviewState
		}) => investmentsOverview,
	)

	return (
		<div className="space-y-6 md:space-y-8 lg:space-y-12">
			<CardWrapper>
				<CardBody>
					<Heading as="h2" styleAs="h5" className="mb-3 sm:truncate">
						{t("profile.settings.app")}
					</Heading>
					<div>
						<Heading as="h2" styleAs="h5">
							Auth
						</Heading>
						<div>lastEmail: {auth.lastEmail}</div>
						<div>rememberMe: {auth.rememberMe}</div>
					</div>

					<div>
						<Heading as="h2" styleAs="h5" className="mb-4">
							userPreferences
						</Heading>
						<div className="mb-4 flex">
							<DropDownComponent
								selectedValueText={`Language: ${userPreferences.lang}`}
								options={Object.values(configs).map(
									(config) => config.path,
								)}
								value={userPreferences.lang}
								onChange={(
									event: ChangeEvent<HTMLSelectElement>,
								) => {
									setLang(
										event.currentTarget.value as Languages,
									)
								}}
							/>
						</div>
					</div>

					<div>
						<Heading as="h2" styleAs="h5">
							investmentsOverview
						</Heading>
						<div className="mb-4 flex">
							<DropDownComponent
								selectedValueText={t(
									"investments:investments.pagination.set_page_size",
									{
										count: investmentsOverview.perPage,
									},
								)}
								value={investmentsOverview.perPage}
								options={projectPageSizes}
								onChange={(
									event: ChangeEvent<HTMLSelectElement>,
								) => {
									dispatch(
										setPerPage(
											Number(
												event.currentTarget.value,
											) as ProjectPageSize,
										),
									)
								}}
							/>
						</div>
						<div className="mb-4 flex">
							<div> Hide project IDS: </div>

							<MultiSelectDropdown
								value={investmentsOverview.hiddenIds}
								onChange={(event: any) => setHiddenIds(event)}
							/>
						</div>

						<div className="mb-4 flex">
							<DropDownComponent
								selectedValueText={`Project sorting: ${investmentsOverview.sortingMethod}`}
								value={investmentsOverview.sortingMethod}
								options={projectSortMethod}
								onChange={(
									event: ChangeEvent<HTMLSelectElement>,
								) => {
									dispatch(
										setSortingMethod(
											event.currentTarget
												.value as ProjectSortMethod,
										),
										setSortingOrder(ProjectSortOrder.Asc),
									)
								}}
							/>
						</div>

						<div className="mb-4 flex">
							<DropDownComponent
								selectedValueText={`Filter type: ${investmentsOverview.filterType}`}
								value={investmentsOverview.filterType}
								options={Object.values(ProjectTypeEnumFiltered)}
								onChange={(
									event: ChangeEvent<HTMLSelectElement>,
								) => {
									dispatch(
										setFilterType(
											event.currentTarget
												.value as ProjectTypeEnumFiltered,
										),
									)
								}}
							/>
						</div>

						<div className="mb-4 flex">
							<DropDownComponent
								selectedValueText={`Filter status: ${investmentsOverview.filterState}`}
								value={investmentsOverview.filterState}
								options={Object.values(
									ProjectStateEnumFiltered,
								)}
								onChange={(
									event: ChangeEvent<HTMLSelectElement>,
								) => {
									dispatch(
										setFilterState(
											event.currentTarget
												.value as ProjectStateEnumFiltered,
										),
									)
								}}
							/>
						</div>

						<div className="mb-4 flex">
							<DropDownComponent
								selectedValueText={`View type: ${investmentsOverview.viewType}`}
								value={investmentsOverview.viewType}
								options={projectPageViewTypes}
								onChange={(
									event: ChangeEvent<HTMLSelectElement>,
								) => {
									dispatch(
										setViewType(
											event.currentTarget
												.value as ProjectPageViewTypes,
										),
									)
								}}
							/>
						</div>
					</div>
				</CardBody>
			</CardWrapper>
		</div>
	)
}

interface DropDownComponentProps {
	selectedValueText: String
	value: string | number
	onChange: Function
	options: any[]
}
const DropDownComponent = ({
	selectedValueText,
	value,
	onChange,
	options = [],
}: DropDownComponentProps) => {
	return (
		<div className="relative">
			<label htmlFor="pageSize w-full">
				<Button size="small" variant="transparent" className="w-full">
					{selectedValueText}
					<FiChevronDown className={"ml-2"} />
					<Select
						name="pageSize"
						className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
						onChange={(event) => onChange(event)}
						value={value ?? undefined}
					>
						{options.map((item) => (
							<option value={item} key={item}>
								{item}
							</option>
						))}
					</Select>
				</Button>
			</label>
		</div>
	)
}

/**
 *  MultiSelectDropdown
 * @param param0
 * @returns
 */
function MultiSelectDropdown({
	onChange,
	value,
}: {
	onChange: Function
	value: Number[]
}) {
	return (
		<div className="relative z-10">
			<Popover.Group className="flex items-center divide-x divide-gray-200">
				<Popover className="relative inline-block text-left">
					<Popover.Button
						as="div"
						className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
					>
						<Button variant="transparent">
							<AnimatePresence
								initial={false}
								key="filter-state-amount"
							>
								<motion.span
									className="overflow-hidden rounded bg-primary-500 text-xs tabular-nums text-black"
									initial={{
										width: 0,
									}}
									animate={{
										width: "auto",
									}}
									exit={{
										width: 0,
									}}
								>
									<span
										className="px-1.5 py-0.5"
										data-testid="payments.list.filter.by_state.count"
									>
										{String(value[0])}
									</span>
								</motion.span>
							</AnimatePresence>
							<ChevronDownIcon
								className="ml-2 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
								aria-hidden="true"
							/>
						</Button>
					</Popover.Button>
					<Transition {...getTransitionPopoverProps()}>
						<Popover.Panel className="absolute left-0 mt-2 origin-top-left rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
							<div
								className="min-w-[300px] space-y-4"
								data-testid="filter-payment-states-desktop"
							>
								{/** Show each value */}
								{value.map((val) => (
									<label
										key={String(val)}
										className="group flex cursor-pointer items-center"
									>
										<Radio
											id="filter-payment-states-done"
											data-testid="filter-payment-states-done"
											name="state"
											onClick={() => {
												onChange([1])
											}}
										/>
										<P className="ml-3 text-sm text-gray-700 group-hover:text-gray-900">
											{String(val)}
										</P>
									</label>
								))}
							</div>
						</Popover.Panel>
					</Transition>
				</Popover>
			</Popover.Group>
		</div>
	)
}
